import { reactive } from 'vue';
import type { ToastData, ToastMessageData } from '~operation/types/toast';
import { TOAST_TYPES } from '~operation/constants/toastType';

export const toastState: ToastData = reactive({
  toast: {
    visible: false,
    message: null,
    status: null,
    timerId: null,
  },
});

export const useToast = () => {
  const updateToast = (visible: boolean = true, message: string | null = null, status: string | null = null, timerId: number | null = null) => {
    toastState.toast = { visible, message, status, timerId };
  };

  const showNotify = (message: ToastMessageData, error: unknown = null): void => {
    if (error === null && message.success !== undefined) {
      showToast(message.success, TOAST_TYPES.ok);
    } else if (error === null && message.accept !== undefined) {
      showToast(message.accept, TOAST_TYPES.accept);
    } else {
      showToast(message.failure, TOAST_TYPES.error);
    }
  };

  const showToast = (message: string, status: string, secondsToClose: number = 3) => {
    if (toastState.toast.timerId) {
      return;
    }
    const timerId = window.setTimeout(() => {
      updateToast(false);
    }, secondsToClose * 1000);
    updateToast(true, message, status, timerId);
  };

  return {
    showToast,
    showNotify,
  };
};
