import type { Router } from 'vue-router';
import { load } from './load';

declare global {
  interface Window {
    dataLayer: unknown[];
  }
}

interface InitializeOptions {
  router: Router;
  measurementId: string;
}

let gtag: (msg: string, payload: unknown) => void;

/**
 * Google Analyticsを初期化
 */
export async function initializeGA(options: InitializeOptions) {
  const { router, measurementId } = options;

  if (!measurementId) {
    console.warn(`measurementId is not found. Skip to initialize GA.`);
    return;
  }

  const url = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;

  try {
    // GAスクリプトを読み込む
    await load(url);

    window.dataLayer = window.dataLayer || [];
    gtag = function () {
      // 公式のサンプルの通り`arguments`にしないと動作しないためESLintルールの方を無効化
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    };

    // 初回アクセスURLがVue Routerによって解決された後に計測を開始する
    await router.isReady();
    gtag('js', new Date());
    gtag('config', measurementId);
    window.gtag = gtag;
  } catch (e) {
    console.error(e);
  }
}
