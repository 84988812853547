export const VITE_APP_API_BASE_URL: string = import.meta.env.VITE_APP_API_BASE_URL as string;
export const VITE_APP_ORG_API_BASE_URL: string = import.meta.env.VITE_APP_ORG_API_BASE_URL as string;
export const VITE_APP_AUTH0_DOMAIN: string = import.meta.env.VITE_APP_AUTH0_DOMAIN as string;
export const VITE_APP_AUTH0_CLIENT_ID: string = import.meta.env.VITE_APP_AUTH0_CLIENT_ID as string;
export const VITE_APP_AUTH0_AUDIENCE: string = import.meta.env.VITE_APP_AUTH0_AUDIENCE as string;
export const VITE_APP_AUTH0_APP_META: string = import.meta.env.VITE_APP_AUTH0_APP_META as string;
export const VITE_APP_AUTH0_SERVICE_ID: string = import.meta.env.VITE_APP_AUTH0_SERVICE_ID as string;
export const VITE_APP_ORG_WEB_URL: string = import.meta.env.VITE_APP_ORG_WEB_URL as string;
export const VITE_APP_ORG_API_FLAG: string = import.meta.env.VITE_APP_ORG_API_FLAG as string;
export const VITE_APP_ADMIN_API_FLAG: string = import.meta.env.VITE_APP_ADMIN_API_FLAG as string;
export const VITE_APP_GA_ENABLED: boolean = import.meta.env.VITE_APP_GA_ENABLED === 'true';
export const VITE_APP_GA_MEASUREMENT_ID: string = import.meta.env.VITE_APP_GA_MEASUREMENT_ID as string;
export const VITE_APP_ROOT_URL: string = import.meta.env.VITE_APP_ROOT_URL as string;
export const VITE_APP_ENABLE_SP_MODE: boolean = import.meta.env.VITE_APP_ENABLE_SP_MODE === 'true';
export const VITE_APP_SENTRY_ENABLED: boolean = import.meta.env.VITE_APP_SENTRY_ENABLED === 'TRUE';
export const VITE_APP_SENTRY_DSN: string = import.meta.env.VITE_APP_SENTRY_DSN as string;
export const VITE_APP_MOCK_ENABLED: boolean = import.meta.env.VITE_APP_MOCK_ENABLED === 'true';
export const VITE_APP_BUILD_DATE: string = import.meta.env.VITE_APP_BUILD_DATE as string;
