import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

export const get: GetResponseResolver = () => {
  // 必要になったらコメントを外して使う
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const LM_OPERATOR = {
    lastName: 'LM',
    firstName: 'User',
    displayName: 'LM User',
    email: 'example@example.com',
    hasLmiRole: true,
    companies: {
      totalCount: 10000,
      code: '9999999999',
    },
    icon: '/operation/img/logo.a42c07e1.png',
    services: [
      {
        service_id: 3,
        name: 'ストレッチクラウド',
        logo: 'https://assets.org.motivation-cloudapp.com/images/logo/logo.sc.png',
        url: 'https://stretch.motivation-cloudapp.com',
      },
    ],
    notifications: [
      {
        type: 'icon',
        title: 'ユーザーアイコンを設定しましょう',
        content: '他の人があなたを判別しやすいように、アイコンを設定しましょう。アイコンはいつでも変更できます。',
        url: 'xxxxx',
      },
    ],
  };

  const COMPANY_USER = {
    lastName: 'Mock',
    firstName: 'User',
    displayName: 'Mock User',
    email: 'example@example.com',
    hasLmiRole: false,
    companies: {
      totalCount: 1,
      code: '9999999999',
    },
    icon: '/operation/img/logo.a42c07e1.png',
    services: [
      {
        service_id: 1,
        name: 'モチベーションクラウド',
        logo: 'https://assets.org.motivation-cloudapp.com/images/logo/logo.mc.png',
        url: 'https://motivation-cloudapp.com',
      },
      {
        service_id: 2,
        name: 'コミュニケーションクラウド',
        logo: 'https://assets.org.motivation-cloudapp.com/images/logo/logo.cc.png',
        url: 'https://communication.motivation-cloudapp.com',
      },
      {
        service_id: 3,
        name: 'ストレッチクラウド',
        logo: 'https://assets.org.motivation-cloudapp.com/images/logo/logo.sc.png',
        url: 'https://stretch.motivation-cloudapp.com',
      },
    ],
    notifications: [
      {
        type: 'icon',
        title: 'ユーザーアイコンを設定しましょう',
        content: '他の人があなたを判別しやすいように、アイコンを設定しましょう。アイコンはいつでも変更できます。',
        url: 'xxxxx',
      },
    ],
  };
  return HttpResponse.json(COMPANY_USER, { status: 200 });
};

export const me = {
  get,
};
