import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const ERROR = '4002';

export const get: GetResponseResolver = async ({ params }) => {
  await new Promise((resolve) => setTimeout(resolve, 500));

  if (params.trainingId === ERROR) {
    return HttpResponse.json(
      {
        status: 'error',
        progress: 100,
        startedAt: '2024-03-06T17:45:19+09:00',
        email: 'ueda.kento+lm@lmi-partners.page',
        errorFileId: 77,
      },
      { status: 200 }
    );
  }

  return HttpResponse.json(
    {
      status: 'success',
      progress: 100,
      startedAt: '2023-02-22T19:24:17+09:00',
      email: 'ueda.kento+lm@lmi-partners.page',
      errorFileId: null,
    },
    { status: 200 }
  );
};

export const trainingFileImportTasksProgress = {
  get,
};
