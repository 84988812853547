import createAuth0Client, { Auth0Client, type LogoutOptions, type RedirectLoginOptions } from '@auth0/auth0-spa-js';
import { jwtDecode } from 'jwt-decode';
import {
  VITE_APP_AUTH0_DOMAIN,
  VITE_APP_AUTH0_CLIENT_ID,
  VITE_APP_AUTH0_AUDIENCE,
  VITE_APP_AUTH0_APP_META,
  VITE_APP_AUTH0_SERVICE_ID,
  VITE_APP_ROOT_URL,
} from '~operation/env';
import { assertIsAuth0Created } from './utils/asserts';

let auth0: Auth0Client | undefined;

export function getAuth0() {
  return auth0;
}

export async function initializeAuth0() {
  auth0 = await createAuth0Client({
    domain: VITE_APP_AUTH0_DOMAIN,
    client_id: VITE_APP_AUTH0_CLIENT_ID,
    audience: VITE_APP_AUTH0_AUDIENCE,
    redirect_uri: `${location.origin}/callback`,
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
  });

  await verifyCachedTokenIsLoginUser();

  return auth0;
}

export async function isAuthenticated() {
  assertIsAuth0Created(auth0);
  return auth0.isAuthenticated();
}

export async function loginWithRedirect(options?: RedirectLoginOptions) {
  assertIsAuth0Created(auth0);
  return auth0.loginWithRedirect({
    service_id: VITE_APP_AUTH0_SERVICE_ID,
    ...options,
  });
}

export async function loginWithRedirectToCurrentPage() {
  const currentLocation = location.pathname + location.search + location.hash;
  loginWithRedirect({ appState: { targetUrl: currentLocation } });
}

export async function handleRedirectCallback() {
  assertIsAuth0Created(auth0);
  return auth0.handleRedirectCallback();
}

export async function getTokenSilently() {
  assertIsAuth0Created(auth0);
  return auth0.getTokenSilently();
}

export async function getUser() {
  assertIsAuth0Created(auth0);
  return auth0.getUser();
}

export async function logout(options?: LogoutOptions) {
  assertIsAuth0Created(auth0);
  removeGuidCookie();
  options = { returnTo: VITE_APP_ROOT_URL, ...options };
  return auth0.logout(options);
}

// キャッシュされているATが「ログインしているユーザー」であるか検証する
// アプリケーション起動時に呼ぶ想定
export async function verifyCachedTokenIsLoginUser() {
  assertIsAuth0Created(auth0);
  // 1. キャッシュされたアクセストークンがない場合は不整合は起きえないのでスキップ
  if (!(await auth0.isAuthenticated())) {
    return;
  }

  const token = await auth0.getTokenSilently();
  const guidInToken = getGuidFromAccessToken(token);
  const guidInCookie = getGuidFromCookie();

  if (!guidInCookie || guidInToken !== guidInCookie) {
    // 1. 初回アクセス時
    // 2. 他サービスでログアウトした
    // 3. cookieの有効期限が切れた
    // 4. 他サービスでユーザーが切り替わった
    try {
      const token = await getTokenSilentlyWithClearCache();
      const guid = getGuidFromAccessToken(token);
      setGuidCookie(guid);
    } catch {
      // 5. Auth0からログアウトした
      removeGuidCookie();
      auth0.logout({ localOnly: true });
      loginWithRedirectToCurrentPage();
    }
  }
}

// キャッシュされているATが「ログインしているユーザー」であるか検証しつつ、アクセストークンを返す
// APIコール時に呼ぶ想定
export async function getTokenSilintlyWithValidate() {
  assertIsAuth0Created(auth0);
  const token = await auth0.getTokenSilently();
  const guidInToken = getGuidFromAccessToken(token);
  const guidInCookie = getGuidFromCookie();

  if (!guidInCookie || guidInToken !== guidInCookie) {
    // 1. 初回アクセス時
    // 2. 他サービスでログアウトした
    // 3. cookieの有効期限が切れた
    // 4. 他サービスでユーザーが切り替わった
    try {
      const token = await getTokenSilentlyWithClearCache();
      const guid = getGuidFromAccessToken(token);
      setGuidCookie(guid);
      return token;
    } catch (e) {
      // 5. Auth0からログアウトした
      removeGuidCookie();
      auth0.logout({ localOnly: true });
      throw e;
    }
  }

  return token;
}

// アクセストークンを取得してクッキーに保存する
// アクセストークンが確実にログインユーザーであることが確定されているログイン後に呼ぶ想定
export async function setGuidCookieAfterHandleRedirectCallback() {
  assertIsAuth0Created(auth0);
  const token = await auth0.getTokenSilently();
  const guid = getGuidFromAccessToken(token);
  setGuidCookie(guid);
}

// キャッシュを削除して、アクセストークンを取得する
// これは強制的にAuth0にトークンをリクエストする
export async function getTokenSilentlyWithClearCache() {
  assertIsAuth0Created(auth0);
  // キャッシュを削除
  auth0.logout({ localOnly: true });

  return auth0.getTokenSilently();
}

// アクセストークンからguidを取り出す
const tokenAndGuidCache = {} as Record<string, string>;
export function getGuidFromAccessToken(token: string) {
  // メモリ上にキャッシュしている場合はキャッシュから返す
  if (tokenAndGuidCache[token]) {
    return tokenAndGuidCache[token];
  }

  try {
    const decoded = jwtDecode(token) as Record<string, { guid: string }>;
    const guid = decoded[VITE_APP_AUTH0_APP_META].guid;
    tokenAndGuidCache[token] = guid;
    return guid;
  } catch {
    return undefined;
  }
}

// cookieからguidを取り出す
export function getGuidFromCookie() {
  const guidRow = document.cookie.split('; ').find((row) => row.startsWith('_mcs_auth0_user_guid'));
  const guid = guidRow ? guidRow.split('=')[1] : undefined;
  return guid;
}

// cookieにguidを保存する
export function setGuidCookie(guid: string | undefined) {
  const domain = getSharedDomain();
  const maxAge = 60 * 60 * 24 * 30;
  document.cookie = `_mcs_auth0_user_guid=${guid}; path=/; domain=${domain}; max-age=${maxAge}`;
}

// cookieからguidを削除する
export function removeGuidCookie() {
  const domain = getSharedDomain();
  document.cookie = `_mcs_auth0_user_guid=; path=/; domain=${domain}; max-age=0`;
}

// サービス間で共有する末尾のドメインを返す
function getSharedDomain() {
  // communication.motivation-cloudapp.com => motivation-cloudapp.com
  // stg.communication.mcs.ninja => mcs.ninja
  // localhost => localhost
  const domain = location.hostname.split('.').slice(-2).join('.');
  return domain;
}
