import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const SYSTEM_ERROR = '500';

export const get: GetResponseResolver = ({ params }) => {
  if (params.companyId === SYSTEM_ERROR) {
    return HttpResponse.json({}, { status: 500 });
  }

  return HttpResponse.json(
    {
      billingServices: {
        basicService: {
          name: '基本サービス',
          current: 19,
          limit: 10000,
        },
        expandedServices: [
          {
            code: '100026',
            name: 'ベーシックD×スキルD',
            current: 300,
            limit: 10000,
          },
          {
            code: '100026',
            name: 'ベーシックA×スキルA',
            current: 20,
            limit: 10000,
          },
        ],
      },
    },
    { status: 200 }
  );
};

export const billingServices = {
  get,
};
