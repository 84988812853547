import { HttpResponse } from 'msw';
import type { GetResponseResolver, PostResponseResolver, DeleteResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const get: GetResponseResolver = async () => {
  return HttpResponse.json({
    answerers: [
      {
        questionnaireAnswererId: 1,
        answererType: 'boss',
        name: '徳野純一',
        email: 'abcdefghijklmnopqrstuvwxyzabcdefghijklmnopqrstuvwxyz@example.com',
      },
      {
        questionnaireAnswererId: 1,
        answererType: 'staff',
        name: '辰巳優斗',
        email: 'abcdefghijklmnopqrstuvwxyzabcdefghijklmnopqrstuvwxyz@example.com',
      },
      {
        questionnaireAnswererId: 1,
        answererType: 'boss',
        name: '酒井英佑',
        email: 'sakai@example.com',
      },
    ],
  });
};

const post: PostResponseResolver = async ({ params }) => {
  const companyUserId = params.companyUserId;

  if (companyUserId === '500') {
    return HttpResponse.json({}, { status: 500 });
  }

  return HttpResponse.json({
    answerers: [
      {
        questionnaireAnswererId: 1,
        answererType: 'boss',
        name: '酒井英佑',
        email: 'sakai@example.com',
      },
    ],
  });
};

const deleteMethod: DeleteResponseResolver = async () => {
  return HttpResponse.json({}, { status: 200 });
};

export const surveyQuestionnaireSettingAnswerTargetUsersAnswerers = {
  get,
  post,
  delete: deleteMethod,
};
