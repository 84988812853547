import Repository from '../Repository';

export default {
  fetchMyPermission(params: { companyCode?: string; companyId?: string }) {
    const { companyCode, companyId } = params;
    return Repository.get(`/my_permissions`, {
      company_code: companyCode,
      company_id: companyId,
    });
  },
};
