import { HttpResponse, delay } from 'msw';
import type { GetResponseResolver, PostResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const NO_SETTING_DATA = '10001';

const get: GetResponseResolver = async ({ params, request }) => {
  const url = new URL(request.url);
  const page = url.searchParams.get('page');

  await delay(300);

  if (params.companyId === NO_SETTING_DATA) {
    return HttpResponse.json(
      {
        totalCount: 0,
        sendMailUserSettings: [],
      },
      { status: 200 }
    );
  }

  if (page == '2') {
    return HttpResponse.json(
      {
        totalCount: 11,
        sendMailUserSettings: [
          {
            companyUserId: 11,
            name: '柴田洋',
            email: 'shibatansan@example.com',
            canSendMail: true,
          },
        ],
      },
      { status: 200 }
    );
  }

  return HttpResponse.json(
    {
      totalCount: 11,
      sendMailUserSettings: [
        {
          companyUserId: 1,
          name: '清水俊則',
          email: 'michel@example.com',
          canSendMail: true,
        },
        {
          companyUserId: 2,
          name: '辰巳優斗',
          email: 'tatsumi@example.com',
          canSendMail: true,
        },
        {
          companyUserId: 3,
          name: '清水愛子',
          email: 'aiko@example.com',
          canSendMail: false,
        },
        {
          companyUserId: 4,
          name: '野田さん',
          email: 'nodanoda@example.com',
          canSendMail: false,
        },
        {
          companyUserId: 5,
          name: '亀山航太',
          email: 'kamepi@example.com',
          canSendMail: true,
        },
        {
          companyUserId: 6,
          name: '植田健人',
          email: 'tatsumi3@example.com',
          canSendMail: true,
        },
        {
          companyUserId: 7,
          name: '岡田大輔',
          email: 'okada@example.com',
          canSendMail: true,
        },
        {
          companyUserId: 8,
          name: '今井恭平',
          email: 'imai@example.com',
          canSendMail: false,
        },
        {
          companyUserId: 9,
          name: '中島裕介',
          email: 'jonasan@example.com',
          canSendMail: false,
        },
        {
          companyUserId: 10,
          name: '渡邉真由',
          email: 'mayuzu@example.com',
          canSendMail: true,
        },
      ],
    },
    { status: 200 }
  );
};

const post: PostResponseResolver = () => {
  return HttpResponse.json({}, { status: 200 });
};

export const sendMailUserSettings = {
  get,
  post,
};
