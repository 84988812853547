import { createRouter, createWebHistory } from 'vue-router';
import type { RouteLocationNormalized, RouteLocationNormalizedLoaded, RouteRecordRaw } from 'vue-router';
import { loginWithRedirect, isAuthenticated } from '~operation/modules/auth/auth0-core';
import { buildRootNavigationGuard } from './guards/root-navigation-guard';
import { PAGE_TITLE } from '~operation/constants/pageTitle';
import type { RouteMeta } from '~operation/router/types';
import { operationCyclesRoutes } from './operationCycles';
import { surveyRoutes } from './operationCycles/surveys';
import { stretchPlansRoutes } from './operationCycles/stretchPlans';
import { trainingsRoutes } from './operationCycles/trainings';
import { usersRoutes } from './users';
import { VITE_APP_MOCK_ENABLED } from '~operation/env';
import { useFeatureFlag } from '~operation/composables/useFeatureFlags';
import { featureFlagRepository } from '~operation/api/withoutAuth/repositories/FeatureFlagRepository';
import { helpBox } from '~operation/static/helpBox';
import { isFeatUserLanguageSetting } from '~operation/utility/featFlg';

const { updateFeatureFlag } = useFeatureFlag();

const routes: RouteRecordRaw[] = [
  {
    path: '/callback',
    name: 'Callback',
    component: () => import(/* webpackChunkName: "Auth" */ '~operation/views/auth/Callback.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "RootFrame" */ '../containers/frame/RootFrame.vue'),
    children: [
      {
        // 契約一覧
        path: '/organizations',
        name: 'organizations',
        component: () => import(/* webpackChunkName: "Organizations" */ '~operation/views/organizations/OrganizationList.vue'),
        meta: {
          requiresAuth: true,
          title: PAGE_TITLE.ORGANIZATIONS,
        } as RouteMeta,
      },
      {
        // 管理画面TOP
        path: '/companies/:companyId',
        name: 'top_page',
        component: () => import(/* webpackChunkName: "Top" */ '~operation/views/operation/top_page/TopPage.vue'),
        props: (route) => ({ companyId: Number(route.params.companyId) }),
        meta: {
          title: PAGE_TITLE.TOP_PAGE,
        } as RouteMeta,
      },

      ...operationCyclesRoutes,
      ...surveyRoutes,
      ...trainingsRoutes,
      ...stretchPlansRoutes,
      ...usersRoutes,

      {
        // システムメール設定
        path: '/companies/:companyId/company-mail-setting',
        name: 'company_mail_setting',
        component: () =>
          import(/* webpackChunkName: "CompanyMailSetting" */ '~operation/views/operation/company_mail_setting/CompanyMailSetting.vue'),
        props: (route) => ({
          companyId: Number(route.params.companyId),
        }),
        meta: {
          requiresAuth: true,
          title: PAGE_TITLE.COMPANY_MAIL_SETTING,
          helpBox: helpBox.company_mail_setting,
        } as RouteMeta,
      },
      {
        // サービス利用状況
        path: '/companies/:companyId/service-usage',
        name: 'serviceUsage',
        component: () => import(/* webpackChunkName: "ServiceUsage" */ '~operation/views/operation/serviceUsage/ServiceUsage.vue'),
        props: (route) => ({
          companyId: Number(route.params.companyId),
        }),
        meta: {
          requiresAuth: true,
          title: PAGE_TITLE.SERVICE_USAGE,
          helpBox: helpBox.serviceUsage,
        } as RouteMeta,
      },
      {
        path: '/error/systemError',
        name: 'systemError',
        component: () => import(/* webpackChunkName: "Error" */ '~operation/views/error/SystemError.vue'),
        meta: {
          title: PAGE_TITLE.ERROR,
        } as RouteMeta,
      },
      {
        path: '/error/notFound',
        name: 'notFound',
        component: () => import(/* webpackChunkName: "Error" */ '~operation/views/error/NotFound.vue'),
        meta: {
          title: PAGE_TITLE.ERROR,
        } as RouteMeta,
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'undefined',
    component: () => import(/* webpackChunkName: "Error" */ '~operation/views/error/NotFound.vue'),
    meta: {
      title: PAGE_TITLE.ERROR,
    } as RouteMeta,
  },
];

if (process.env.NODE_ENV !== 'production') {
  routes.unshift({
    path: '/',
    component: () => import(/* webpackChunkName: "RootFrame" */ '../containers/frame/RootFrame.vue'),
    children: [
      {
        path: '/dev_list',
        name: 'dev_list',
        component: () => import(/* webpackChunkName: "DevList" */ '~operation/views/operation/dev_list/DevList.vue'),
        meta: { requiresAuth: false },
      },
    ],
  });
} else {
  routes.unshift({
    path: '/',
    component: () => import(/* webpackChunkName: "RootFrame" */ '../containers/frame/RootFrame.vue'),
    children: [
      // {
      //   path: '/',
      //   name: 'root',
      // },
    ],
  });
}

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded, savedPosition: any) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' };
    }
    return { left: 0, top: 0 };
  },
});

router.beforeEach(async (to: RouteLocationNormalized) => {
  const companyId = to.params.companyId as string;
  if (companyId) {
    const featureFlag = await featureFlagRepository.fetchFeatureFlags(companyId);
    updateFeatureFlag(featureFlag);
  }

  if (isInValidRequest(to)) {
    return {
      name: 'notFound',
    };
  }

  const getRequiresAuth = () => {
    if (process.env.NODE_ENV === 'development' && VITE_APP_MOCK_ENABLED) {
      return false;
    }
    if (to.meta?.requiresAuth ?? true) {
      return true;
    }
    return false;
  };
  if (!getRequiresAuth()) {
    return;
  }

  const isAuthed = await isAuthenticated();
  if (isAuthed) {
    return;
  }

  loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  return false;
});

router.beforeEach(buildRootNavigationGuard());

router.afterEach((to) => {
  document.title = to.meta?.title ? `${to.meta?.title} - 管理 - ${PAGE_TITLE.DEFAULT_TITLE}` : PAGE_TITLE.DEFAULT_TITLE;
});

const isInValidRequest = (to: RouteLocationNormalized) => {
  /**
   * (例)
   * if (to.name === 'route_name') {
   *   return !featFlag.value
   * }
   */

  if (to.name === 'additionalQuestionsTranslation') {
    return !isFeatUserLanguageSetting.value;
  }

  return false;
};
