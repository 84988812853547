import { HttpResponse } from 'msw';
import type { PostResponseResolver } from '~operation/mocks/types/RestResponseResolver';

export const post: PostResponseResolver = () => {
  return HttpResponse.json(
    {
      startedAt: '2023-02-22T15:23:46+09:00',
      email: 'sample@example.com',
    },
    { status: 200 }
  );
};

export const trainingFileImportTaskImport = {
  post,
};
