import { HttpResponse } from 'msw';
import type { GetResponseResolver, PostResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const EMPTY_CASE = '2';
const SYSTEM_ERROR = '500';

export const get: GetResponseResolver = ({ params }) => {
  if (params.companyId === SYSTEM_ERROR) {
    return HttpResponse.json({}, { status: 500 });
  }

  if (params.operationCycleId === EMPTY_CASE) {
    return HttpResponse.json(
      {
        totalTrainingsCount: 0,
        trainings: [],
      },
      { status: 200 }
    );
  }

  return HttpResponse.json(
    {
      totalTrainingsCount: 2,
      trainings: [
        {
          id: 2,
          type: 'サーベイフィードバック研修（全日）',
          startedAt: '2023-02-28T14:00:00+09:00',
          participantsCount: 1,
          status: '設定完了',
        },
        {
          id: 1,
          type: 'サーベイフィードバック研修（全日）',
          startedAt: '2023-02-10T10:00:00+09:00',
          participantsCount: 0,
          status: '設定未完了',
        },
      ],
    },
    { status: 200 }
  );
};

export const post: PostResponseResolver = () => {
  return HttpResponse.json(
    {
      training: {
        id: 46,
      },
    },
    { status: 200 }
  );
};

export const operationCycleTrainings = {
  get,
  post,
};
