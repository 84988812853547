import {
  BULK_QUESTIONNAIRE_SETTING,
  BULK_QUESTIONNAIRE_SETTING_CREATE_FILE,
  BULK_STRETCH_PLAN_QUESTIONNAIRE_SETTING_CREATE_FILE,
  BULK_TRAINING_QUESTIONNAIRE_SETTING,
  BULK_TRAINING_QUESTIONNAIRE_SETTING_CREATE_FILE,
  CONFIRM_PLAN_AND_SERVICE_USAGE_STATUS,
  CONFIRM_RESPONSE_STATUS,
  CONFIRM_RESULT,
  CONFIRM_STRETCH_PLAN_INPUT_STATUS,
  CONFIRM_SURVEY_DETAIL,
  CREATE_OPERATION_CYCLE,
  DECIDE_REVIEW_SETTING,
  NOT_GET_MAIL,
  NOT_SHOW_IMAGE,
  SETTING_ADDITIONAL_QUESTIONS,
  SETTING_MAIL,
  SETTING_STRETCH_PLAN,
  SETTING_TARGET_AUDIENCE,
  WHAT_IS_IP,
} from '~operation/static/links/helpPage';
import type { HelpBoxType } from '~operation/router/types';

export const helpBox: HelpBoxType = {
  // システムメール設定
  company_mail_setting: {
    faq: [
      { title: 'メールが届かない', url: NOT_GET_MAIL },
      { title: 'IPアドレスとは', url: WHAT_IS_IP },
      { title: 'メールの画像が表示されない', url: NOT_SHOW_IMAGE },
    ],
  },
  // サービス利用状況
  serviceUsage: {
    manuals: [{ title: '契約中のプランとサービス利用状況を確認する', url: CONFIRM_PLAN_AND_SERVICE_USAGE_STATUS }],
  },
  // 運用設定新規
  create_operation_cycle: {
    manuals: [{ title: '運用設定を登録する', url: CREATE_OPERATION_CYCLE }],
  },
  // サーベイ詳細
  survey_detail: {
    manuals: [
      { title: 'サーベイ実施内容を確認する', url: CONFIRM_SURVEY_DETAIL },
      { title: '回答状況を確認する', url: CONFIRM_RESPONSE_STATUS },
      { title: '結果を確認する', url: CONFIRM_RESULT },
    ],
  },
  // サーベイ/回答依頼メール
  request_answer_mail: {
    manuals: [{ title: 'メールを設定する', url: SETTING_MAIL }],
  },
  // サーベイ/回答促進メール
  remind_answer_mail: {
    manuals: [{ title: 'メールを設定する', url: SETTING_MAIL }],
  },
  // 回答促進メール 送信一覧
  remind_answer_mail_list: {
    manuals: [{ title: 'メールを設定する', url: SETTING_MAIL }],
  },
  // 追加設問
  additional_questions: {
    manuals: [{ title: '追加設問を設定する', url: SETTING_ADDITIONAL_QUESTIONS }],
  },
  // サーベイ対象者・回答者の一括設定
  bulk_questionnaire_setting: {
    manuals: [
      { title: '対象者・回答者を登録する', url: BULK_QUESTIONNAIRE_SETTING },
      { title: '対象者・回答者一覧ファイルのつくり方', url: BULK_QUESTIONNAIRE_SETTING_CREATE_FILE },
      { title: '閲覧対象者の設定方法', url: SETTING_TARGET_AUDIENCE },
    ],
  },
  // 研修詳細
  training_detail: {
    manuals: [
      { title: '研修参加者を設定する', url: BULK_TRAINING_QUESTIONNAIRE_SETTING },
      { title: '研修参加者一覧ファイルのつくり方', url: BULK_TRAINING_QUESTIONNAIRE_SETTING_CREATE_FILE },
    ],
  },
  // ストレッチプラン詳細
  stretch_plan_detail: {
    manuals: [
      { title: 'ストレッチプランの実施設定をする', url: SETTING_STRETCH_PLAN },
      { title: '振り返り設定を決める手順', url: DECIDE_REVIEW_SETTING },
      { title: 'ストレッチプランの入力状況を確認する', url: CONFIRM_STRETCH_PLAN_INPUT_STATUS },
    ],
  },
  // ストレッチプラン/登録依頼メール設定
  stretch_plan_request_mail: {
    manuals: [{ title: 'ストレッチプランの実施設定をする', url: SETTING_STRETCH_PLAN }],
  },
  // ストレッチプラン/登録促進メール設定
  stretch_plan_remind_mail: {
    manuals: [{ title: 'ストレッチプランの実施設定をする', url: SETTING_STRETCH_PLAN }],
  },
  // ストレッチプラン/振り返り入力依頼メール
  stretch_plan_progress_request_mail: {
    manuals: [{ title: 'ストレッチプランの実施設定をする', url: SETTING_STRETCH_PLAN }],
  },
  // ストレッチプラン/振り返り入力促進メール
  stretch_plan_progress_remind_mail: {
    manuals: [{ title: 'ストレッチプランの実施設定をする', url: SETTING_STRETCH_PLAN }],
  },
  // ストレッチプラン対象者の一括設定
  bulk_stretch_plan_settings: {
    manuals: [{ title: 'ストレッチプラン対象者一覧ファイルのつくり方', url: BULK_STRETCH_PLAN_QUESTIONNAIRE_SETTING_CREATE_FILE }],
  },
};
