import { HttpResponse } from 'msw';
import type { GetResponseResolver, PostResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const EMPTY_CASE = '2';
const FORBIDDEN = '403';
const NOT_FOUND = '404';
const CONFLICT = '409';
const SYSTEM_ERROR = '500';

export const get: GetResponseResolver = ({ params }) => {
  if (params.companyId === EMPTY_CASE) {
    return HttpResponse.json(
      {
        totalCount: 0,
        operationCycles: [],
      },
      { status: 200 }
    );
  }

  if (params.companyId === FORBIDDEN) {
    return HttpResponse.json({}, { status: 403 });
  }

  if (params.companyId === NOT_FOUND) {
    return HttpResponse.json({}, { status: 404 });
  }

  if (params.companyId === CONFLICT) {
    return HttpResponse.json({}, { status: 409 });
  }

  if (params.companyId === SYSTEM_ERROR) {
    return HttpResponse.json({}, { status: 500 });
  }

  return HttpResponse.json(
    {
      totalCount: 12,
      operationCycles: [
        {
          id: 1,
          name: '育成サイクル名',
          survey: {
            id: 1,
            typeName: 'マネジメントサーベイ',
            startedAt: '2021-04-01T00:00:00+09:00',
            isInPreparation: false,
            isExistParticipant: false,
          },
          training: {
            id: 1,
            typeName: 'サーベイFBアドバイスセッション（組織エンゲージメントサーベイ/マネジメントサーベイ両方）',
            startedAt: '2021-04-01T00:00:00+09:00',
            isInPreparation: false,
          },
          stretchPlan: {
            id: 1,
            startedAt: '2021-04-01T00:00:00+09:00',
            endedAt: '2021-04-10T00:00:00+09:00',
            isInPreparation: true,
          },
        },
      ],
    },
    { status: 200 }
  );
};

const post: PostResponseResolver = () => {
  return HttpResponse.json({ operationCycle: { id: 1 } }, { status: 200 });
};

export const operationCycles = {
  get,
  post,
};
