import type { ToastTypeData, ToastMessageData } from '~operation/types/toast';

export const TOAST_TYPES: ToastTypeData = {
  ok: 'ok',
  accept: 'accept',
  error: 'error',
};

// 押下ボタンが「追加」の場合下記を使用してください 2022/3/10
export const TOAST_MESSAGE_ADDITION: ToastMessageData = {
  success: '追加しました',
  failure: '追加に失敗しました',
};
// 押下ボタンが「新規登録/更新」系の場合下記を使用してください 2022/1/14
export const TOAST_MESSAGE_SAVE: ToastMessageData = {
  success: '保存しました',
  failure: '保存に失敗しました',
};
// 押下ボタンが「削除」系の場合下記を使用してください 2022/1/14
export const TOAST_MESSAGE_DELETE: ToastMessageData = {
  success: '削除しました',
  failure: '削除に失敗しました',
};
// 押下ボタンが上記いずれにも当てはまらない場合(公開、確定など)は下記を使用してください  2022/1/14
export const TOAST_MESSAGE_SUCCESS: ToastMessageData = {
  success: '成功しました',
  failure: '失敗しました',
};
export const TOAST_MESSAGE_MAIL: ToastMessageData = {
  success: '送信しました',
  failure: '送信に失敗しました',
};
// 一括登録画面の場合下記を使用してください 2022/1/27
export const TOAST_MESSAGE_ACCEPT: ToastMessageData = {
  accept: 'アップロードを受け付けました。画面をリロードし、履歴より結果をご確認ください',
  failure: '保存に失敗しました',
};
// 排他処理の場合下記を使用してください 2022/1/27
export const TOAST_MESSAGE_EXCLUSIVE_TASK: ToastMessageData = {
  success: '成功しました',
  failure: 'データ処理中のため、使用できません。管理画面のトップに移動します',
};
// OrgApiがメンテナンスの場合下記を使用してください 2022/10/31
export const TOAST_MESSAGE_ORG_MAINTENANCE: ToastMessageData = {
  success: '成功しました',
  failure: 'メンテナンス中です',
};

// 共通のAPI実行時エラー
export const TOAST_MESSAGE_SYSTEM_ERROR: ToastMessageData = {
  failure: '通信できませんでした。時間をおいて再度お試しください。',
};

// 取得API404時エラー
export const TOAST_MESSAGE_NOT_FOUND_ERROR: ToastMessageData = {
  failure: 'データが見つかりませんでした',
};
