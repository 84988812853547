import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const SYSTEM_ERROR = '500';
const EMPTY = '3';

export const get: GetResponseResolver = ({ params }) => {
  if (params.companyId === SYSTEM_ERROR) {
    return HttpResponse.json({}, { status: 500 });
  }
  if (params.operationCycleId === EMPTY) {
    return HttpResponse.json(
      {
        totalSurveysCount: 1,
        surveys: [],
      },
      { status: 200 }
    );
  }

  return HttpResponse.json(
    {
      totalSurveysCount: 300,
      surveys: [
        {
          id: 2,
          type: 'マネジメントサーベイ',
          startedAt: '2024-02-07T10:00:00+09:00',
          endedAt: '2024-02-11T18:00:00+09:00',
          answerTargetUsersCount: 0,
          answerersCount: 0,
          status: '設定未完了',
        },
        {
          id: 1,
          type: 'マネジメントサーベイ',
          startedAt: '2023-01-24T19:00:00+09:00',
          endedAt: '2023-01-31T19:00:00+09:00',
          answerTargetUsersCount: 2,
          answerersCount: 1,
          status: '終了',
        },
      ],
    },
    { status: 200 }
  );
};

export const operationCycleSurveys = {
  get,
};
