import { HttpResponse } from 'msw';
import type { PostResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const ERROR_400 = '400';

const post: PostResponseResolver = ({ params }) => {
  if (params.stretchPlanId === ERROR_400) {
    return HttpResponse.json(
      {
        code: 'validation_error',
        message: 'バリデーションに失敗しました',
        validationErrors: [
          {
            field: 'file',
            messages: ['ファイルの形式が正しくありません。拡張子が「.csv」のファイルを選択してください。'],
          },
        ],
      },
      { status: 400 }
    );
  }

  return HttpResponse.json(
    {
      fileImportTaskId: 1,
      stretchPlanManagedUsers: {
        before: 0,
        after: 1,
        add: 1,
        delete: 0,
      },
    },
    { status: 200 }
  );
};

export const stretchPlanTargetUsersCSVUpload = { post };
