import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

export const get: GetResponseResolver = ({ params }) => {
  if (Number(params.companyId) % 2 === 1) {
    return HttpResponse.json(
      {
        service: 'expanded_service',
      },
      { status: 200 }
    );
  }

  return HttpResponse.json(
    {
      service: 'ticket_service',
    },
    { status: 200 }
  );
};

export const planService = {
  get,
};
