import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

export const get: GetResponseResolver = async () => {
  await new Promise((resolve) => setTimeout(resolve, 500));

  return HttpResponse.json(
    {
      status: 'success',
      progress: 100,
      startedAt: '2023-02-22T18:46:59+09:00',
      email: 'test@example.com',
      errorFileId: null,
    },
    { status: 200 }
  );
};

export const stretchPlanFileImportTasksProgress = {
  get,
};
