import { type RouteRecordRaw } from 'vue-router';
import type { RouteMeta } from '~operation/router/types';
import { PAGE_TITLE } from '~operation/constants/pageTitle';
import { helpBox } from '~operation/static/helpBox';
import { isFeatIndividualAnswererSettings } from '~operation/utility/featFlg';

export const surveyRoutes: RouteRecordRaw[] = [
  {
    // サーベイ詳細
    path: '/companies/:companyId/operation_cycles/:operationCycleId/surveys/:surveyId',
    name: 'survey_detail',
    component: () => import(/* webpackChunkName: "SurveyDetail" */ '~operation/views/operation/survey_detail/SurveyDetail.vue'),
    props: (route) => ({
      companyId: Number(route.params.companyId),
      operationCycleId: Number(route.params.operationCycleId),
      surveyId: Number(route.params.surveyId),
    }),
    meta: {
      title: PAGE_TITLE.SURVEY_DETAIL,
      helpBox: helpBox.survey_detail,
    } as RouteMeta,
  },
  {
    // サーベイ/回答依頼メール
    path: '/companies/:companyId/operation_cycles/:operationCycleId/surveys/:surveyId/request_answer_mail',
    name: 'request_answer_mail',
    component: () => import(/* webpackChunkName: "RequestAnswerMail" */ '~operation/views/operation/request_answer_mail/RequestAnswerMail.vue'),
    props: (route) => ({
      companyId: Number(route.params.companyId),
      operationCycleId: Number(route.params.operationCycleId),
      surveyId: Number(route.params.surveyId),
    }),
    meta: {
      title: PAGE_TITLE.REQUEST_ANSWER_MAIL,
      helpBox: helpBox.request_answer_mail,
    } as RouteMeta,
  },
  {
    // サーベイ/回答促進メール
    path: '/companies/:companyId/operation_cycles/:operationCycleId/surveys/:surveyId/remind_answer_mail',
    name: 'remind_answer_mail',
    component: () => import(/* webpackChunkName: "RemindAnswerMail" */ '~operation/views/operation/remind_answer_mail/RemindAnswerMail.vue'),
    props: (route) => ({
      companyId: Number(route.params.companyId),
      operationCycleId: Number(route.params.operationCycleId),
      surveyId: Number(route.params.surveyId),
    }),
    meta: {
      title: PAGE_TITLE.REMIND_ANSWER_MAIL,
      helpBox: helpBox.remind_answer_mail,
    } as RouteMeta,
  },
  {
    // 回答促進メール 送信一覧
    path: '/companies/:companyId/operation_cycles/:operationCycleId/surveys/:surveyId/remind_answer_mail_list',
    name: 'remind_answer_mail_list',
    component: () =>
      import(/* webpackChunkName: "RemindAnswerMailList" */ '~operation/views/operation/remind_answer_mail_list/RemindAnswerMailList.vue'),
    props: (route) => ({
      companyId: Number(route.params.companyId),
      operationCycleId: Number(route.params.operationCycleId),
      surveyId: Number(route.params.surveyId),
    }),
    meta: {
      title: PAGE_TITLE.REMIND_ANSWER_MAIL_LIST,
      helpBox: helpBox.remind_answer_mail_list,
    } as RouteMeta,
  },
  {
    // 追加設問
    path: '/companies/:companyId/operation_cycles/:operationCycleId/surveys/:surveyId/additional_questions',
    name: 'additional_questions',
    component: () => import(/* webpackChunkName: "AdditionalQuestions" */ '~operation/views/operation/additional_questions/AdditionalQuestions.vue'),
    props: (route) => ({
      companyId: Number(route.params.companyId),
      operationCycleId: Number(route.params.operationCycleId),
      surveyId: Number(route.params.surveyId),
    }),
    meta: {
      title: PAGE_TITLE.ADDITIONAL_QUESTIONS,
      helpBox: helpBox.additional_questions,
    } as RouteMeta,
  },
  {
    // 追加設問翻訳
    path: '/companies/:companyId/operation_cycles/:operationCycleId/surveys/:surveyId/additional_questions/translation',
    name: 'additionalQuestionsTranslation',
    component: () => import('~operation/views/operation/additionalQuestionsTranslation/AdditionalQuestionsTranslation.vue'),
    props: (route) => ({
      companyId: Number(route.params.companyId),
      operationCycleId: Number(route.params.operationCycleId),
      surveyId: Number(route.params.surveyId),
    }),
    meta: { title: PAGE_TITLE.ADDITIONAL_QUESTIONS_TRANSLATION } as RouteMeta,
  },
  {
    // サーベイ対象者・回答者の一括設定
    path: '/companies/:companyId/operation_cycles/:operationCycleId/surveys/:surveyId/bulk_questionnaire_setting',
    name: 'bulk_questionnaire_setting',
    component: () =>
      import(/* webpackChunkName: "BulkQuestionnaireSetting" */ '~operation/views/operation/bulk_questionnaire_setting/BulkQuestionnaireSetting.vue'),
    props: (route) => ({
      companyId: Number(route.params.companyId),
      operationCycleId: Number(route.params.operationCycleId),
      surveyId: Number(route.params.surveyId),
    }),
    meta: {
      title: PAGE_TITLE.BULK_QUESTIONNAIRE_SETTING,
      helpBox: helpBox.bulk_questionnaire_setting,
    } as RouteMeta,
  },
  {
    // サーベイ回答促進メール個別送信
    path: '/companies/:companyId/operation_cycles/:operationCycleId/surveys/:surveyId/resend-remind-mail',
    name: 'surveyResendRemindMail',
    component: () =>
      import(/* webpackChunkName: "SurveyResendRemindMail" */ '~operation/views/operation/surveys/resendRemindMail/ResendRemindMail.vue'),
    props: (route) => ({
      companyId: Number(route.params.companyId),
      operationCycleId: Number(route.params.operationCycleId),
      surveyId: Number(route.params.surveyId),
    }),
    meta: { title: PAGE_TITLE.SURVEY_RESEND_REMIND_MAIL } as RouteMeta,
  },
];

if (isFeatIndividualAnswererSettings.value) {
  surveyRoutes.push(
    {
      // 回答対象者一覧
      path: '/companies/:companyId/operation_cycles/:operationCycleId/surveys/:surveyId/answer-target-users',
      name: 'surveyAnswerTargetUsers',
      component: () => import('~operation/views/operation/surveys/answerTargetUsers/AnswerTargetUsers.vue'),
      props: (route) => ({
        companyId: Number(route.params.companyId),
        operationCycleId: Number(route.params.operationCycleId),
        surveyId: Number(route.params.surveyId),
      }),
      meta: { title: PAGE_TITLE.SURVEY_ANSWER_TARGET_USERS } as RouteMeta,
    },
    {
      // 回答対象者詳細
      path: '/companies/:companyId/operation_cycles/:operationCycleId/surveys/:surveyId/answer-target-users/:answerTargetUserId',
      name: 'surveyAnswerTargetUser',
      component: () => import('~operation/views/operation/surveys/answerTargetUser/AnswerTargetUser.vue'),
      props: (route) => ({
        companyId: Number(route.params.companyId),
        operationCycleId: Number(route.params.operationCycleId),
        surveyId: Number(route.params.surveyId),
        answerTargetUserId: Number(route.params.answerTargetUserId),
      }),
      meta: { title: PAGE_TITLE.SURVEY_ANSWER_TARGET_USER } as RouteMeta,
    }
  );
}
