import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const get: GetResponseResolver = async ({ request }) => {
  const url = new URL(request.url);
  const answererType = url.searchParams.get('answerer_type');

  if (answererType === 'boss') {
    return HttpResponse.json({
      answerers: Array.from({ length: 100 }, (_, index) => ({
        companyUserId: index,
        name: `上司${index + 1}`,
        email: `boss${index + 1}@example.com`,
      })),
    });
  }

  return HttpResponse.json({
    answerers: Array.from({ length: 100 }, (_, index) => ({
      companyUserId: index,
      name: `部下${index + 1}`,
      email: `staff${index + 1}@example.com`,
    })),
  });
};

export const surveyQuestionnaireSettingAnswerTargetUsersAnswererCandidates = {
  get,
};
