import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const SETTING_COMPLETE_CASE = '3';
const COMPLETE_CASE = '5';
const OUT_OF_PERIOD_CASE = '6';
const SAME_MAX_AND_MIN_PARTICIPANT = '7';
const SYSTEM_ERROR = '500';
const TICKET_SHORTFALL = '1000';
const TICKET_SHORTFALL_WITH_GC = '1001';
const EXCEEDED_UPDATE_DEADLINE_OF_EXPECTED_MIN_PARTICIPANT = '114';
const SETTING_COMPLETE_EXCEEDED_UPDATE_DEADLINE_OF_EXPECTED_MIN_PARTICIPANT = '314';

export const get: GetResponseResolver = ({ params }) => {
  if (params.companyId === SYSTEM_ERROR) {
    return HttpResponse.json({}, { status: 500 });
  }

  if (params.trainingId === SETTING_COMPLETE_CASE) {
    return HttpResponse.json(
      {
        training: {
          typeId: 1,
          type: 'サーベイフィードバック研修（全日）',
          status: '設定完了',
          remainingDays: 13,
          startedAt: '2023-11-15T14:00:00+09:00',
          endedAt: '2023-11-15T15:00:00+09:00',
          consumptionTicketPerParticipant: 1,
          hasGroupCoordinator: false,
          groupCount: null,
          maxParticipantCountByGroup: null,
          expectedParticipantCount: 12,
          participantCount: 1,
          participantsCount: 1,
          isConfirmed: true,
          isConfirmable: false,
          isOutOfPeriod: false,
          isExceededUpdateDeadlineOfExpectedMinParticipant: true,
        },
      },
      { status: 200 }
    );
  }

  if (params.trainingId === COMPLETE_CASE) {
    return HttpResponse.json(
      {
        training: {
          typeId: 2,
          type: 'サーベイフィードバック研修（半日）',
          status: '終了',
          remainingDays: 13,
          startedAt: '2023-11-13T09:00:00+09:00',
          endedAt: '2023-11-13T16:00:00+09:00',
          consumptionTicketPerParticipant: 1,
          hasGroupCoordinator: false,
          groupCount: null,
          maxParticipantCountByGroup: null,
          expectedParticipantCount: 12,
          participantCount: 1,
          participantsCount: 1,
          isConfirmed: true,
          isConfirmable: false,
          isOutOfPeriod: false,
          isExceededUpdateDeadlineOfExpectedMinParticipant: true,
        },
      },
      { status: 200 }
    );
  }

  if (params.trainingId === OUT_OF_PERIOD_CASE) {
    return HttpResponse.json(
      {
        training: {
          typeId: 2,
          type: 'サーベイフィードバック研修（半日）',
          status: '設定未完了',
          remainingDays: 13,
          startedAt: '2053-01-16T09:00:00+09:00',
          endedAt: '2053-01-16T16:00:00+09:00',
          consumptionTicketPerParticipant: 2,
          hasGroupCoordinator: true,
          groupCount: 3,
          maxParticipantCountByGroup: 4,
          expectedParticipantCount: 11,
          participantCount: 1,
          participantsCount: 0,
          isConfirmed: false,
          isConfirmable: false,
          isOutOfPeriod: true,
          isExceededUpdateDeadlineOfExpectedMinParticipant: true,
        },
      },
      { status: 200 }
    );
  }

  if (params.trainingId === SAME_MAX_AND_MIN_PARTICIPANT) {
    return HttpResponse.json(
      {
        training: {
          typeId: 1,
          type: 'サーベイフィードバック研修（全日）',
          status: '設定未完了',
          remainingDays: 33,
          startedAt: '2023-02-10T09:00:00+09:00',
          endedAt: '2023-02-10T11:00:00+09:00',
          consumptionTicketPerParticipant: 2,
          hasGroupCoordinator: true,
          groupCount: 1,
          maxParticipantCountByGroup: 5,
          expectedParticipantCount: 5,
          participantCount: 0,
          participantsCount: 0,
          isConfirmed: false,
          isConfirmable: false,
          isOutOfPeriod: false,
          isExceededUpdateDeadlineOfExpectedMinParticipant: false,
        },
      },
      { status: 200 }
    );
  }

  if (params.trainingId === TICKET_SHORTFALL) {
    return HttpResponse.json(
      {
        training: {
          typeId: 1,
          type: 'サーベイフィードバック研修（全日）',
          status: '設定未完了',
          remainingDays: 33,
          startedAt: '2023-02-10T10:00:00+09:00',
          endedAt: '2023-02-10T11:00:00+09:00',
          consumptionTicketPerParticipant: 1,
          hasGroupCoordinator: false,
          groupCount: null,
          maxParticipantCountByGroup: null,
          expectedParticipantCount: 11,
          participantCount: 0,
          participantsCount: 0,
          isConfirmed: false,
          isConfirmable: false,
          isOutOfPeriod: false,
          isExceededUpdateDeadlineOfExpectedMinParticipant: false,
        },
      },
      { status: 200 }
    );
  }

  if (params.trainingId === TICKET_SHORTFALL_WITH_GC) {
    return HttpResponse.json(
      {
        training: {
          typeId: 1,
          type: 'サーベイフィードバック研修（全日）',
          status: '設定未完了',
          remainingDays: 33,
          startedAt: '2023-02-10T10:00:00+09:00',
          endedAt: '2023-02-10T11:00:00+09:00',
          consumptionTicketPerParticipant: 2,
          hasGroupCoordinator: true,
          groupCount: 3,
          maxParticipantCountByGroup: 4,
          expectedParticipantCount: 11,
          participantCount: 0,
          participantsCount: 0,
          isConfirmed: false,
          isConfirmable: false,
          isOutOfPeriod: false,
          isExceededUpdateDeadlineOfExpectedMinParticipant: false,
        },
      },
      { status: 200 }
    );
  }

  if (params.trainingId === EXCEEDED_UPDATE_DEADLINE_OF_EXPECTED_MIN_PARTICIPANT) {
    return HttpResponse.json(
      {
        training: {
          typeId: 2,
          type: 'サーベイFBアドバイスセッション（組織エンゲージメントサーベイ/マネジメントサーベイ両方）',
          status: '設定未完了',
          remainingDays: 13,
          startedAt: '2023-02-10T10:00:00+09:00',
          endedAt: '2023-02-10T11:00:00+09:00',
          consumptionTicketPerParticipant: 2,
          hasGroupCoordinator: true,
          groupCount: 3,
          maxParticipantCountByGroup: 4,
          expectedParticipantCount: 11,
          participantCount: 0,
          participantsCount: 0,
          isConfirmed: false,
          isConfirmable: false,
          isOutOfPeriod: false,
          isExceededUpdateDeadlineOfExpectedMinParticipant: true,
        },
      },
      { status: 200 }
    );
  }

  if (params.trainingId === SETTING_COMPLETE_EXCEEDED_UPDATE_DEADLINE_OF_EXPECTED_MIN_PARTICIPANT) {
    return HttpResponse.json(
      {
        training: {
          typeId: 2,
          type: 'サーベイFBアドバイスセッション（組織エンゲージメントサーベイ/マネジメントサーベイ両方）',
          status: '設定完了',
          remainingDays: 13,
          startedAt: '2023-02-10T10:00:00+09:00',
          endedAt: '2023-02-10T11:00:00+09:00',
          consumptionTicketPerParticipant: 2,
          hasGroupCoordinator: true,
          groupCount: 3,
          maxParticipantCountByGroup: 4,
          expectedParticipantCount: 11,
          participantCount: 15,
          participantsCount: 15,
          isConfirmed: true,
          isConfirmable: false,
          isOutOfPeriod: false,
          isExceededUpdateDeadlineOfExpectedMinParticipant: true,
        },
      },
      { status: 200 }
    );
  }

  return HttpResponse.json(
    {
      training: {
        typeId: 1,
        type: 'サーベイフィードバック研修（全日）',
        status: '設定未完了',
        remainingDays: 33,
        startedAt: '2023-02-10T10:00:00+09:00',
        endedAt: '2023-02-10T11:00:00+09:00',
        consumptionTicketPerParticipant: 2,
        hasGroupCoordinator: true,
        groupCount: 8,
        maxParticipantCountByGroup: 4,
        expectedParticipantCount: 29,
        participantCount: 0,
        participantsCount: 0,
        isConfirmed: false,
        isConfirmable: false,
        isOutOfPeriod: false,
        isExceededUpdateDeadlineOfExpectedMinParticipant: false,
      },
    },
    { status: 200 }
  );
};

export const trainingConfig = {
  get,
};
