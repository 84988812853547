import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

export const get: GetResponseResolver = ({ request }) => {
  const url = new URL(request.url);
  const companyId = url.searchParams.get('company_id');

  return HttpResponse.json(
    {
      id: 1,
      code: companyId,
      name: 'サンプル商事',
    },
    { status: 200 }
  );
};

export const organization = {
  get,
};
