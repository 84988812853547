import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

export const get: GetResponseResolver = () => {
  return HttpResponse.json(
    {
      trainingBillingTypes: [
        { id: '1', name: 'サーベイフィードバック研修（全日）', hasGroupCoordinator: true, consumptionTicketPerParticipant: 2 },
        {
          id: '2',
          name: 'サーベイFBアドバイスセッション（組織エンゲージメントサーベイ/マネジメントサーベイ両方）',
          hasGroupCoordinator: true,
          consumptionTicketPerParticipant: 1,
        },
        { id: '3', name: 'スキル研修（半日）', hasGroupCoordinator: false, consumptionTicketPerParticipant: 1 },
        { id: '4', name: 'GCなし研修1', hasGroupCoordinator: false, consumptionTicketPerParticipant: 1 },
      ],
    },
    { status: 200 }
  );
};

export const trainingBillingTypes = {
  get,
};
