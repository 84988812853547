import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const EMPTY_CASE = '2';
const FULL_RESERVATION_CASE = '3';
const ENDED_CASE = '4';
const SYSTEM_ERROR = '500';

export const get: GetResponseResolver = ({ params }) => {
  if (params.companyId === SYSTEM_ERROR) {
    return HttpResponse.json({}, { status: 500 });
  }

  if (params.surveyId === EMPTY_CASE) {
    return HttpResponse.json(
      {
        startedAt: '2023-02-21T14:00:00+09:00',
        endedAt: '2053-02-28T14:00:00+09:00',
        unsentRemindersCount: 0,
        unsentReminders: [],
        sentReminders: [],
      },
      { status: 200 }
    );
  }

  if (params.surveyId === FULL_RESERVATION_CASE) {
    return HttpResponse.json(
      {
        startedAt: '2023-02-21T14:00:00+09:00',
        endedAt: '2053-02-28T14:00:00+09:00',
        unsentRemindersCount: 10,
        unsentReminders: [
          {
            id: 1,
            sendAt: '2023-03-08T10:00:00+09:00',
          },
          {
            id: 2,
            sendAt: '2023-03-09T10:00:00+09:00',
          },
          {
            id: 3,
            sendAt: '2023-03-09T11:00:00+09:00',
          },
          {
            id: 4,
            sendAt: '2023-03-09T12:00:00+09:00',
          },
          {
            id: 5,
            sendAt: '2023-03-09T13:00:00+09:00',
          },
          {
            id: 6,
            sendAt: '2023-03-09T14:00:00+09:00',
          },
          {
            id: 7,
            sendAt: '2023-03-09T15:00:00+09:00',
          },
          {
            id: 8,
            sendAt: '2023-03-09T16:00:00+09:00',
          },
          {
            id: 9,
            sendAt: '2023-03-09T17:00:00+09:00',
          },
          {
            id: 10,
            sendAt: '2023-03-09T18:00:00+09:00',
          },
        ],
        sentReminders: [],
      },
      { status: 200 }
    );
  }

  if (params.surveyId === ENDED_CASE) {
    return HttpResponse.json(
      {
        startedAt: '2023-02-21T14:00:00+09:00',
        endedAt: '2023-02-28T14:00:00+09:00',
        unsentRemindersCount: 0,
        unsentReminders: [],
        sentReminders: [
          {
            id: 1,
            sendAt: '2023-02-08T10:00:00+09:00',
          },
          {
            id: 2,
            sendAt: '2023-02-09T10:00:00+09:00',
          },
          {
            id: 3,
            sendAt: '2023-02-09T11:00:00+09:00',
          },
          {
            id: 4,
            sendAt: '2023-02-09T12:00:00+09:00',
          },
          {
            id: 5,
            sendAt: '2023-02-09T13:00:00+09:00',
          },
          {
            id: 6,
            sendAt: '2023-02-09T14:00:00+09:00',
          },
          {
            id: 7,
            sendAt: '2023-02-09T15:00:00+09:00',
          },
          {
            id: 8,
            sendAt: '2023-02-09T16:00:00+09:00',
          },
          {
            id: 9,
            sendAt: '2023-02-09T17:00:00+09:00',
          },
          {
            id: 10,
            sendAt: '2023-02-09T18:00:00+09:00',
          },
        ],
      },
      { status: 200 }
    );
  }

  return HttpResponse.json(
    {
      startedAt: '2023-03-06T10:00:00+09:00',
      endedAt: '2053-03-10T18:00:00+09:00',
      unsentRemindersCount: 2,
      unsentReminders: [
        {
          id: 2,
          sendAt: '2023-09-22T10:00:00+09:00',
        },
        {
          id: 3,
          sendAt: '2023-09-23T10:00:00+09:00',
        },
      ],
      sentReminders: [
        {
          id: 1,
          sendAt: '2023-03-07T10:00:00+09:00',
        },
      ],
    },
    { status: 200 }
  );
};

export const surveyMailReminders = {
  get,
};
