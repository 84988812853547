import type { Router } from 'vue-router';
import { VITE_APP_GA_ENABLED, VITE_APP_GA_MEASUREMENT_ID } from '~operation/env';
import { initializeGA } from './utils/initialize';

const initGa4Module = async (router: Router) => {
  const gaEnabled = VITE_APP_GA_ENABLED;
  const gaMeasurementId = VITE_APP_GA_MEASUREMENT_ID;

  if (gaEnabled) {
    await initializeGA({ measurementId: gaMeasurementId, router });
  }
};

export { initGa4Module };
