import { AxiosError } from 'axios';
import { loginWithRedirect } from '~operation/modules/auth';
import { errorCodes } from '~operation/constants/api';
import { TOAST_MESSAGE_EXCLUSIVE_TASK, TOAST_MESSAGE_SYSTEM_ERROR, TOAST_MESSAGE_NOT_FOUND_ERROR } from '~operation/constants/toastType';
import { useToast } from '~operation/composables/use-toast';

const privateAPIRetryLimit = 'private_api_retry_limit';
const maintenance = 'maintenance';

export const handleError = (error: AxiosError, shouldShowErrorPage: boolean) => {
  const toast = useToast();

  if (error.config) {
    error.config.data = { shouldShowErrorPage };
  }

  if (Number(error?.response?.status) >= 500) {
    toast.showToast(TOAST_MESSAGE_SYSTEM_ERROR.failure, 'error');
    throw error;
  }

  if (isUnauthorizedError(error?.response?.status)) {
    loginWithRedirect();
    throw error;
  }

  if (error?.response?.status === 409) {
    toast.showNotify(TOAST_MESSAGE_EXCLUSIVE_TASK, 'error');
    throw error;
  }

  if (error?.response?.data.code !== privateAPIRetryLimit && error?.response?.data.code !== maintenance) {
    if (isNotFoundError(error?.response?.status)) {
      toast.showToast(TOAST_MESSAGE_NOT_FOUND_ERROR.failure, 'error');
    } else if (isSystemError(error?.response?.status)) {
      toast.showToast(TOAST_MESSAGE_SYSTEM_ERROR.failure, 'error');
    }
  }

  if (isClientError(error.response?.status)) {
    error.code = errorCodes.clientError;
  } else if (isNotFoundError(error.response?.status)) {
    error.code = errorCodes.notFoundError;
  } else if (isSystemError(error.response?.status)) {
    error.code = errorCodes.systemError;
  }
  throw error;
};

const isUnauthorizedError = (responseStatusCode: number | undefined) => responseStatusCode === 401;
const isClientError = (responseStatusCode: number | undefined) => responseStatusCode === 400;
const isNotFoundError = (responseStatusCode: number | undefined) => responseStatusCode === 404 || responseStatusCode === 403;
// 400, 401, 403, 404以外を一旦 SystemErrorと定義する
const isSystemError = (responseStatusCode: any) => {
  const isExclusionTarget = isUnauthorizedError(responseStatusCode) || isClientError(responseStatusCode) || isNotFoundError(responseStatusCode);
  return responseStatusCode >= 400 && !isExclusionTarget;
};
