import { HttpResponse } from 'msw';
import type { GetResponseResolver, PostResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const get: GetResponseResolver = () => {
  const sentMailHistories = [];
  const sentMails = [];

  for (let n = 1; n < 15; n++) {
    sentMails.push({
      receiver: {
        companyUserId: n,
        name: 'ユーザー' + n,
        email: `test${n}@example.com`,
      },
      status: 'success',
    });
  }

  sentMailHistories.push(
    {
      sendAt: '2024-02-01T10:00:00+09:00',
      sender: {
        name: '設定者',
      },
      mails: sentMails,
    },
    {
      sendAt: '2024-01-31T10:00:00+09:00',
      sender: {
        name: '長いユーザー名の設定者長いユーザー名の設定者長いユーザー名の設定者長いユーザー名の設定者長いユーザー名の設定者',
      },
      mails: [
        {
          receiver: {
            companyUserId: 100,
            name: '長いユーザー名長いユーザー名',
            email: 'abcdefghijklmnopqrstuvwxyz0123456789.abcdefghijklmnopqrstuvwxyz0123456789.abcdefghijklmnopqrstuvwxyz0123456789@example.com',
          },
          status: 'success',
        },
        {
          receiver: {
            companyUserId: 101,
            name: `ユーザー名`,
            email: 'test101@example.com',
          },
          status: 'failed',
        },
        {
          receiver: null,
          status: null,
        },
      ],
    },
    {
      sendAt: '2024-01-30T17:00:00+09:00',
      sender: {
        name: '設定者',
      },
      mails: [
        {
          receiver: {
            companyUserId: 1000,
            name: 'ユーザー1000',
            email: 'test1000@example.com',
          },
          status: 'success',
        },
        {
          receiver: {
            companyUserId: 1001,
            name: 'ユーザー1001',
            email: 'test1001@example.com',
          },
          status: 'failed',
        },
      ],
    },
    {
      sendAt: '2024-01-29T10:00:00+09:00',
      sender: null,
      mails: [
        {
          receiver: {
            companyUserId: 10000,
            name: 'ユーザー10000',
            email: 'test10000@example.com',
          },
          status: 'failed',
        },
        {
          receiver: null,
          status: 'failed',
        },
      ],
    }
  );

  return HttpResponse.json(
    {
      sentMailHistories,
    },
    { status: 200 }
  );
};

const post: PostResponseResolver = () => {
  return HttpResponse.json(
    {
      sentMailHistory: {
        sendAt: '2024-02-01T17:00:00+09:00',
        sender: {
          name: '設定者',
        },
        mails: [
          {
            receiver: {
              companyUserId: 10000,
              name: '送信済ユーザー',
              email: 'test+done@example.com',
            },
            status: null,
          },
        ],
      },
    },
    { status: 200 }
  );
};

export const stretchPlanIndividualRemindMails = {
  get,
  post,
};
