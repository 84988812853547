<template>
  <ErrorFrame :errorImage="errorImage">
    <template #contents>
      <p>INTERNAL SERVER ERROR</p>
    </template>
    <template #reason>
      <p>画面が表示できませんでした - 500</p>
    </template>
    <template #description>
      <p>
        メンテナンス中かプログラミングエラーが発生している可能性があります<br />
        ご不便をおかけして、申し訳ございません<br />
        しばらくしてから再度お試しください
      </p>
    </template>
  </ErrorFrame>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ErrorFrame from '~operation/components/Error/ErrorFrame.vue';

export default defineComponent({
  name: 'SystemError',
  components: { ErrorFrame },
  setup() {
    const errorImage = '/operation/images/error_caution.svg';

    return {
      errorImage,
    };
  },
});
</script>
