import { ref } from 'vue';

const isLoading = ref<boolean>(false);
const startLoading = () => {
  isLoading.value = true;
};

const endLoading = () => {
  isLoading.value = false;
};

export const usePage = () => {
  return {
    isLoading,
    startLoading,
    endLoading,
  };
};
