<template>
  <ErrorFrame :errorImage="errorImage">
    <template #contents>
      <p>PAGE NOT FOUND</p>
    </template>
    <template #reason>
      <p>画面が表示できませんでした - 404</p>
    </template>
    <template #description>
      <p>アクセスしたURLが正しいものであることをご確認ください</p>
    </template>
  </ErrorFrame>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ErrorFrame from '~operation/components/Error/ErrorFrame.vue';

export default defineComponent({
  name: 'NotFound',
  components: { ErrorFrame },
  setup() {
    const errorImage = '/operation/images/error_404.svg';

    return {
      errorImage,
    };
  },
});
</script>
