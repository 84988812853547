// 管理者用ヘルプページトップ
export const ADMIN_HELP_PAGE_TOP = 'https://admin.stretch-support.motivation-cloudapp.com';

// データシートの文字化け
export const WINDOWS_ZIP_FILE = 'https://admin.stretch-support.motivation-cloudapp.com/hc/ja/articles/6258848959513';

// ユーザー一覧ファイルのつくり方
export const BULK_USER_SETTING = 'https://admin.stretch-support.motivation-cloudapp.com/hc/ja/articles/6257225318553';

// 権限プランの一括設定の入力例
export const BULK_USERS_PLAN_SETTING = 'https://admin.stretch-support.motivation-cloudapp.com/hc/ja/articles/6257225318553';

// サーベイ対象者・回答者の一括設定の入力例 / 対象者・回答者を登録する
export const BULK_QUESTIONNAIRE_SETTING =
  'https://admin.stretch-support.motivation-cloudapp.com/hc/ja/articles/6257774669977-%E5%AF%BE%E8%B1%A1%E8%80%85-%E5%9B%9E%E7%AD%94%E8%80%85%E3%82%92%E7%99%BB%E9%8C%B2%E3%81%99%E3%82%8B#h_01G2S0TXBN0JJWWSSVACBFE2X3';

// サーベイ対象者・回答者の一括設定の入力例 / 対象者・回答者一覧ファイルのつくり方
export const BULK_QUESTIONNAIRE_SETTING_CREATE_FILE = 'https://admin.stretch-support.motivation-cloudapp.com/hc/ja/articles/6257865866265';

// 研修参加者の一括設定の入力例 / 研修参加者を設定する
export const BULK_TRAINING_QUESTIONNAIRE_SETTING = 'https://admin.stretch-support.motivation-cloudapp.com/hc/ja/articles/6258654139033';

// 研修参加者の一括設定の入力例 / 研修参加者一覧ファイルのつくり方
export const BULK_TRAINING_QUESTIONNAIRE_SETTING_CREATE_FILE = 'https://admin.stretch-support.motivation-cloudapp.com/hc/ja/articles/6258656465817';

// SP対象者の一括設定の入力例
export const BULK_STRETCH_PLAN_QUESTIONNAIRE_SETTING =
  'https://admin.stretch-support.motivation-cloudapp.com/hc/ja/articles/6258728721177-%E3%82%B9%E3%83%88%E3%83%AC%E3%83%83%E3%83%81%E3%83%97%E3%83%A9%E3%83%B3%E3%81%AE%E5%AE%9F%E6%96%BD%E8%A8%AD%E5%AE%9A%E3%82%92%E3%81%99%E3%82%8B#h_01G61V4HBJE4S1Q1Q06A9JP33Z';

// SP対象者の一括設定の入力例
export const BULK_STRETCH_PLAN_QUESTIONNAIRE_SETTING_CREATE_FILE =
  'https://admin.stretch-support.motivation-cloudapp.com/hc/ja/articles/6258769964185';

// 回答画面アクセスできない
export const ANSWER_PAGE_ACCESS = {
  JA: 'https://answerer.stretch-support.motivation-cloudapp.com/hc/ja/articles/6833035810201',
  EN: 'https://answerer.stretch-support.motivation-cloudapp.com/hc/en-us/articles/6833035810201',
};

// 一括で回答する
export const ANSWER_SURVEY_BULK_ANSWER =
  'https://answerer.stretch-support.motivation-cloudapp.com/hc/ja/articles/6716124597785-%E4%B8%80%E6%8B%AC%E5%9B%9E%E7%AD%94%E3%81%AE%E5%85%A5%E5%8A%9B%E4%BE%8B';

// SCヘルプセンター/ リクエストフォーム(answer)
export const REQUEST_FORM_FOR_ANSWER = {
  JA: 'https://answerer.stretch-support.motivation-cloudapp.com/hc/ja/requests/new',
  EN: 'https://answerer.stretch-support.motivation-cloudapp.com/hc/en-us/requests/new',
};

// SCヘルプセンター/ リクエストフォーム(user)
export const REQUEST_FORM_FOR_USER = {
  JA: 'https://user.stretch-support.motivation-cloudapp.com/hc/ja/requests/new',
  EN: 'https://user.stretch-support.motivation-cloudapp.com/hc/en-us/requests/new',
};

// メールが届かない
export const NOT_GET_MAIL = 'https://admin.stretch-support.motivation-cloudapp.com/hc/ja/articles/10678027179801';

// IPアドレスとは
export const WHAT_IS_IP = 'https://admin.stretch-support.motivation-cloudapp.com/hc/ja/articles/10678103379353';

// メール内の画像が表示されない
export const NOT_SHOW_IMAGE = 'https://admin.stretch-support.motivation-cloudapp.com/hc/ja/articles/10678108896793';

// 契約中のプランとサービス利用状況を確認する
export const CONFIRM_PLAN_AND_SERVICE_USAGE_STATUS = 'https://admin.stretch-support.motivation-cloudapp.com/hc/ja/articles/6257259674009';

// 運用設定を登録する
export const CREATE_OPERATION_CYCLE = 'https://admin.stretch-support.motivation-cloudapp.com/hc/ja/articles/6257565499033';

// サーベイ実施内容を確認する
export const CONFIRM_SURVEY_DETAIL = 'https://admin.stretch-support.motivation-cloudapp.com/hc/ja/articles/6258529017881';

// 回答状況を確認する
export const CONFIRM_RESPONSE_STATUS = 'https://admin.stretch-support.motivation-cloudapp.com/hc/ja/articles/6258551478169';

// 結果を確認する
export const CONFIRM_RESULT = 'https://admin.stretch-support.motivation-cloudapp.com/hc/ja/articles/6258618631705';

// 閲覧対象者の設定方法
export const SETTING_TARGET_AUDIENCE = 'https://admin.stretch-support.motivation-cloudapp.com/hc/ja/articles/18526678978713';

// 追加設問を設定する
export const SETTING_ADDITIONAL_QUESTIONS = 'https://admin.stretch-support.motivation-cloudapp.com/hc/ja/articles/6258492706457';

// メールを設定する
export const SETTING_MAIL = 'https://admin.stretch-support.motivation-cloudapp.com/hc/ja/articles/6258496130201';

// ストレッチプランの実施設定をする
export const SETTING_STRETCH_PLAN = 'https://admin.stretch-support.motivation-cloudapp.com/hc/ja/articles/6258728721177';

// 振り返り設定を決める手順
export const DECIDE_REVIEW_SETTING = 'https://admin.stretch-support.motivation-cloudapp.com/hc/ja/articles/16276483594009';

// ストレッチプランの入力状況を確認する
export const CONFIRM_STRETCH_PLAN_INPUT_STATUS = 'https://admin.stretch-support.motivation-cloudapp.com/hc/ja/articles/6258867195673';
