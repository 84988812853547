import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const SYSTEM_ERROR = '500';

export const get: GetResponseResolver = ({ params }) => {
  if (params.companyId === SYSTEM_ERROR) {
    return HttpResponse.json({}, { status: 500 });
  }

  return HttpResponse.json(
    {
      operationCycleNames: ['運用設定名1', '運用設定名2', '運用設定名3'],
    },
    { status: 200 }
  );
};

export const operationCycleNames = {
  get,
};
