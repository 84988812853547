import { type RouteRecordRaw } from 'vue-router';
import type { RouteMeta } from '~operation/router/types';
import { PAGE_TITLE } from '~operation/constants/pageTitle';
import { helpBox } from '~operation/static/helpBox';

export const operationCyclesRoutes: RouteRecordRaw[] = [
  {
    // 運用設定詳細
    path: '/companies/:companyId/operation_cycles/:operationCycleId(\\d+)',
    name: 'operation_cycle_detail',
    component: () =>
      import(/* webpackChunkName: "OperationCycleDetail" */ '~operation/views/operation/operation_cycle_detail/OperationCycleDetail.vue'),
    props: (route) => ({
      companyId: Number(route.params.companyId),
      operationCycleId: Number(route.params.operationCycleId),
    }),
    meta: {
      requiresAuth: true,
      title: PAGE_TITLE.OPERATION_CYCLE_DETAIL,
    } as RouteMeta,
  },
  {
    // 運用設定新規
    path: '/companies/:companyId/operation_cycles/create',
    name: 'create_operation_cycle',
    component: () =>
      import(/* webpackChunkName: "CreateOperationCycle" */ '~operation/views/operation/create_operation_cycle/CreateOperationCycle.vue'),
    props: (route) => ({ companyId: Number(route.params.companyId) }),
    meta: {
      title: PAGE_TITLE.OPERATION_CYCLE_CREATE,
      helpBox: helpBox.create_operation_cycle,
    } as RouteMeta,
  },
  {
    // 運用設定編集
    path: '/companies/:companyId/operation_cycles/:operationCycleId/edit',
    name: 'edit_operation_cycle',
    component: () => import(/* webpackChunkName: "EditOperationCycle" */ '~operation/views/operation/edit_operation_cycle/EditOperationCycle.vue'),
    props: (route) => ({
      companyId: Number(route.params.companyId),
      operationCycleId: Number(route.params.operationCycleId),
    }),
    meta: {
      title: PAGE_TITLE.OPERATION_CYCLE_EDIT,
    } as RouteMeta,
  },
];
