import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const SURVEY_COMPLETE = '5';
const SYSTEM_ERROR = '500';

export const get: GetResponseResolver = ({ params }) => {
  if (params.companyId === SYSTEM_ERROR) {
    return HttpResponse.json({}, { status: 500 });
  }

  if (params.surveyId === SURVEY_COMPLETE) {
    return HttpResponse.json(
      {
        survey: {
          id: 5,
          finishRemainingDays: 0,
          answererStatus: {
            noDelivery: 0,
            ready: 0,
            inProgress: 0,
            complete: 4,
            totalAnswerers: 4,
          },
          calculableStatus: {
            incalculable: 0,
            insufficient: 0,
            sufficient: 0,
            calculable: 2,
            totalTargetAnswerers: 2,
          },
        },
      },
      { status: 200 }
    );
  }

  return HttpResponse.json(
    {
      survey: {
        id: 1,
        finishRemainingDays: 29,
        answererStatus: {
          noDelivery: 0,
          ready: 0,
          inProgress: 0,
          complete: 0,
          totalAnswerers: 0,
        },
        calculableStatus: {
          incalculable: 0,
          insufficient: 0,
          sufficient: 0,
          calculable: 0,
          totalTargetAnswerers: 0,
        },
      },
    },
    { status: 200 }
  );
};

export const surveyAnswerStatus = {
  get,
};
