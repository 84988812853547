import Repository from '../Repository';
import { VITE_APP_ORG_API_FLAG } from '~operation/env';

const mockHead = {
  displayName: 'Mock User',
  email: 'example@example.com',
  hasLmiRole: false,
  companies: {
    totalCount: 1,
    code: '9999999999',
  },
  icon: '/operation/img/logo.a42c07e1.png',
  services: [
    {
      service_id: 1,
      name: 'モチベーションクラウド',
      logo: 'https://assets.org.motivation-cloudapp.com/images/logo/logo.mc.png',
      url: 'https://motivation-cloudapp.com',
    },
    {
      service_id: 2,
      name: 'コミュニケーションクラウド',
      logo: 'https://assets.org.motivation-cloudapp.com/images/logo/logo.cc.png',
      url: 'https://communication.motivation-cloudapp.com',
    },
    {
      service_id: 3,
      name: 'ストレッチクラウド',
      logo: 'https://assets.org.motivation-cloudapp.com/images/logo/logo.sc.png',
      url: 'https://stretch.motivation-cloudapp.com',
    },
  ],
  notifications: [
    {
      type: 'icon',
      title: 'ユーザーアイコンを設定しましょう',
      content: '他の人があなたを判別しやすいように、アイコンを設定しましょう。アイコンはいつでも変更できます。',
      url: 'xxxxx',
    },
  ],
};

function wait(ms: number) {
  return new Promise<void>((resolve) => setTimeout(() => resolve(), ms));
}

export default {
  fetchSurveyInformation(companyId: number, surveyId: number) {
    return Repository.get(`/operations/companies/${companyId}/surveys/${surveyId}`);
  },
  fetchTrainingInformation(companyId: number, trainingId: number) {
    return Repository.get(`/operations/companies/${companyId}/trainings/${trainingId}`);
  },
  fetchStretchPlanInformation(companyId: number, stretchPlanId: number) {
    return Repository.get(`/operations/companies/${companyId}/stretch_plans/${stretchPlanId}`);
  },
  async fetchLoginUserInfo(companyId?: number) {
    if (VITE_APP_ORG_API_FLAG !== 'true') {
      await wait(500);
      return mockHead;
    }
    return Repository.get('/me', { company_id: companyId });
  },
  async fetchCompanyIdByCode(companyCode: string) {
    return Repository.get('/organization', { company_code: companyCode });
  },
  async fetchCompanyCodeById(companyId: string) {
    return Repository.get('/organization', { company_id: companyId });
  },
};
