import type { NavigationGuard, NavigationGuardNext, Route } from 'vue-router';
import headersRepository from '~operation/api/withAuth/repositories/HeadersRepository';
import permissionRepository from '~operation/api/withAuth/repositories/PermissionRepository';
import { usePage } from '~operation/composables/usePage';
import type { Permission } from '~operation/types/permission';

const page = usePage();

/**
 * ルートURLにアクセスしたときに、適切な画面へと遷移させるナビゲーションガード
 */
export function buildRootNavigationGuard(): NavigationGuard {
  return async (to, from, next) => {
    /**
     * 個別企業画面へのアクセスは別途権限ガードで処理するのでスキップする
     */
    if (!isRootAccess(to)) {
      next();
      return;
    }

    /**
     * URLにクエリパラメータ `?company_code` がついている場合は、
     * organizationsから企業を選択してリダイレクトされている
     *
     * ついていない場合は、直接SCのルートURLにアクセスしているので、
     * ユーザーが単一企業所属か複数企業所属か判別して、リダイレクト先を決める
     */
    let companyCode = (to.query.company_code as string) ?? undefined;
    if (!companyCode) {
      const data: { companyCount: number; companyCode: string } | undefined = await getCompanyCountAndCode();

      if (data) {
        const { companyCount, companyCode: userCompanyCode } = data;
        if (companyCount > 1) {
          // 複数企業の場合には組織一覧へ遷移する
          location.href = `/organizations`;
        } else if (companyCount === 1) {
          // 単一企業所属の場合はユーザーが属する唯一の企業コードを保持して後続で処理
          companyCode = userCompanyCode;
        } else {
          next({ name: 'notFound' });
          return;
        }
      }
    }
    await redirectToAvailableRoute({ companyCode, next });
  };
}

const isRootAccess = (route: Route) => {
  return route.path === '/';
};

/**
 * ログインユーザーの所属企業数と（単一企業所属の場合の）企業コードを返す
 */
const getCompanyCountAndCode = async () => {
  page.startLoading();
  const userInfo = await headersRepository.fetchLoginUserInfo().finally(() => {
    page.endLoading();
  });
  const companyCount = userInfo.companies.totalCount;
  const companyCode = userInfo.companies.code;
  return { companyCount, companyCode };
};

/**
 * 権限情報を元にユーザーがアクセスできる画面にリダイレクト
 */
const redirectToAvailableRoute = async (params: { companyCode: string; next: NavigationGuardNext }) => {
  const { companyCode, next } = params;

  page.startLoading();
  const [myPermission, company] = await Promise.all([
    permissionRepository.fetchMyPermission({ companyCode }),
    headersRepository.fetchCompanyIdByCode(companyCode),
  ]).finally(() => {
    page.endLoading();
  });

  const permissions = myPermission.permissions as Permission[];
  const { id: companyId } = company;

  if (permissions.includes('operation_setting')) {
    next({ name: 'top_page', params: { companyId } });
  } else if (permissions.includes('survey_target') || permissions.includes('stretch_plan_target')) {
    location.href = `/result/companies/${companyId}/my_results`;
  } else if (permissions.includes('follower')) {
    location.href = `/follow/companies/${companyId}/follow-users`;
  } else {
    next({ name: 'notFound' });
  }
};
