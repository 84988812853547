import { HttpResponse } from 'msw';
import type { PostResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const ERROR_400 = '400';
const SYSTEM_ERROR = '500';

const post: PostResponseResolver = ({ params }) => {
  if (params.companyId === SYSTEM_ERROR) {
    return HttpResponse.json({}, { status: 500 });
  }

  if (params.surveyId === ERROR_400) {
    return HttpResponse.json(
      {
        code: 'validation_error',
        message: 'バリデーションに失敗しました',
        validationErrors: [
          {
            field: 'file',
            messages: ['1行目の項目と2行目以降の値の組み合わせが正しくありません。1行目の項目は変更せず、新しい列も追加しないでください。'],
          },
        ],
      },
      { status: 400 }
    );
  }
  return HttpResponse.json(
    {
      fileImportTaskId: 1,
      questionnaireAnswerers: {
        before: 4,
        after: 9,
        add: 6,
        delete: 1,
      },
      answerersSendingRequestMail: {
        new: 0,
        add: 0,
      },
      isExistAnswerToDelete: false,
    },
    { status: 200 }
  );
};

export const surveyQuestionnaireSettingCSVUpload = { post };
