import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

export const get: GetResponseResolver = ({ request }) => {
  const url = new URL(request.url);
  const companyId = url.searchParams.get('company_id');

  if (companyId === '1') {
    return HttpResponse.json(
      {
        aiAdviceFlag: true,
        multilingalizationFlag: true,
        ticketServiceFlag: true,
        userLanguageSettingFlag: true,
      },
      { status: 200 }
    );
  } else {
    return HttpResponse.json(
      {
        aiAdviceFlag: false,
        multilingalizationFlag: false,
        ticketServiceFlag: true,
        userLanguageSettingFlag: false,
      },
      { status: 200 }
    );
  }
};

export const featureFlags = {
  get,
};
