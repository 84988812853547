import { HttpResponse } from 'msw';
import type { GetResponseResolver, PostResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const SYSTEM_ERROR = '500';

const get: GetResponseResolver = ({ params }) => {
  if (params.companyId === SYSTEM_ERROR) {
    return HttpResponse.json({}, { status: 500 });
  }

  return HttpResponse.json(
    {
      company_mail_setting: {
        send_limit: 50,
        is_text_mail_selected: false,
      },
    },
    { status: 200 }
  );
};

const post: PostResponseResolver = () => {
  return HttpResponse.json({}, { status: 200 });
};

export const companyServiceCompanyMailSetting = {
  get,
  post,
};
