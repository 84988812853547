import { trainingSharedUrlListFileDownloadUrl } from './api/trainingSharedUrlListFileDownloadUrl';
import { http } from 'msw';
import { operationCycles } from './api/operationCycles';
import { operationCycle } from './api/operationCycle';
import { operationCycleContactEmail } from './api/operationCycleContactEmail';
import { operationCycleNames } from './api/operationCycleNames';
import { operationCycleStretchPlans } from './api/operationCycleStretchPlans';
import { operationCycleSurveys } from './api/operationCycleSurveys';
import { operationCycleSurveysWithQuestionnaires } from './api/operationCycleSurveysWithQuestionnaires';
import { operationCycleTrainings } from './api/operationCycleTrainings';
import { operationCycleMailMessage } from './api/operationCycleMailMessage';
import { operationCycleMailLocalizedContent } from './api/operationCycleMailLocalizedContent';
import { operationCycleMailContent } from './api/operationCycleMailContent';
import { operationCycleMailIncludeAddressee } from './api/operationCycleMailIncludeAddressee';
import { operationCycleHidden } from './api/operationCycleHidden';

import { planService } from './api/planService';
import { tickets } from './api/tickets';
import { runningTasks } from './api/runningTasks';
import { runningTasksProgress } from './api/runningTasksProgress';
import { organizations } from './api/organizations';
import { organization } from './api/organization';
import { me } from './api/me';
import { myPermissions } from './api/myPermissions';
import { currentTime } from './api/currentTime';

import { featureFlags } from './api/featureFlags';

import { survey } from './api/survey';
import { surveyAnswerStatus } from './api/surveyAnswerStatus';
import { surveyConfig } from './api/surveyConfig';
import { surveyAdditionalQuestions } from './api/surveyAdditionalQuestions';
import { surveyAdditionalQuestionsTranslation } from './api/surveyAdditionalQuestionsTranslation';
import { surveyResultNotificationMailSchedule } from './api/surveyResultNotificationMailSchedule';
import { surveyFileImportTasksProgress } from './api/surveyFileImportTasksProgress';
import { surveyFileImportTaskImport } from './api/surveyFileImportTaskImport';
import { surveyMailReminders } from './api/surveyMailReminders';
import { surveyQuestionnaireSettingCSVUpload } from './api/surveyQuestionnaireSettingCSVUpload';
import { surveyQuestionnaireSettingUploadHistories } from './api/surveyQuestionnaireSettingUploadHistories';
import { surveyViewerSettingCSVUpload } from './api/surveyViewerSettingCSVUpload';
import { surveyViewerFileImportTaskImport } from './api/surveyViewerFileImportTaskImport';
import { surveyViewerFileImportTasksProgress } from './api/surveyViewerFileImportTasksProgress';
import { additionalQuestionResultDisclosable } from './api/additionalQuestionResultDisclosable';
import { surveyQuestionnaireAnswerers } from './api/surveyQuestionnaireAnswerers';
import { surveyIndividualReminderMails } from './api/surveyIndividualReminderMails';
import { companyUser } from './api/companyUser';
import { surveyQuestionnaireSettingAnswerTargetUsers } from './api/surveyQuestionnaireSettingAnswerTargetUsers';
import { surveyQuestionnaireSettingAnswerTargetUsersAnswerers } from './api/surveyQuestionnaireSettingAnswerTargetUsersAnswerers';
import { surveyQuestionnaireSettingAnswerTargetUsersAnswererCandidates } from './api/surveyQuestionnaireSettingAnswerTargetUsersAnswererCandidates';

import { trainingBillingTypes } from './api/trainingBillingTypes';
import { training } from './api/training';
import { trainingPlanService } from './api/trainingPlanService';
import { trainingAvailableTickets } from './api/trainingAvailableTickets';
import { trainingConfig } from './api/trainingConfig';
import { trainingPeriod } from './api/trainingPeriod';
import { trainingExpectedParticipants } from './api/trainingExpectedParticipants';
import { trainingFileImportTaskImport } from './api/trainingFileImportTaskImport';
import { trainingFileImportTasksProgress } from './api/trainingFileImportTasksProgress';
import { trainingFileImportTasks } from './api/trainingFileImportTasks';
import { trainingTrainingSettingCSVUpload } from './api/trainingTrainingSettingCSVUpload';

import { stretchPlan } from './api/stretchPlan';
import { stretchPlanConfig } from './api/stretchPlanConfig';
import { stretchPlanFileImportTaskImport } from './api/stretchPlanFileImportTaskImport';
import { stretchPlanRemindMailSchedules } from './api/stretchPlanRemindMailSchedules';
import { stretchPlanFileImportTasksProgress } from './api/stretchPlanFileImportTasksProgress';
import { stretchPlanProgressMailSchedules } from './api/stretchPlanProgressMailSchedules';
import { stretchPlanFileImportTasks } from './api/stretchPlanFileImportTasks';
import { stretchPlanTargetUsersCSVUpload } from './api/stretchPlanTargetUsersCSVUpload';
import { stretchPlanManagedUsers } from './api/stretchPlanManagedUsers';
import { stretchPlanIndividualRemindMails } from './api/stretchPlanIndividualRemindMails';

import { companyPlans } from './api/companyPlans';
import { billingServices } from './api/billingServices';

import { companyServiceCompanyMailSetting } from './api/org/companyServiceCompanyMailSetting';
import { sendMailUserSettings } from './api/sendMailUserSettings';
import { firstPlanning } from './api/firstPlanning';

const REQUEST_BASE_URL = 'http://localhost:50200/v1';
const ORG_REQUEST_BASE_URL = 'http://localhost:50000/org/v1';

export const handlers = [
  http.get(`${REQUEST_BASE_URL}/me`, me.get),
  http.get(`${REQUEST_BASE_URL}/organizations`, organizations.get),
  http.get(`${REQUEST_BASE_URL}/organization`, organization.get),

  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/plan_service`, planService.get),
  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/tickets`, tickets.get),
  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/running_tasks`, runningTasks.get),
  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/common_masters/sync_histories/progress`, runningTasksProgress.get),

  http.get(`${REQUEST_BASE_URL}/my_permissions`, myPermissions.get),
  http.get(`${REQUEST_BASE_URL}/current_times`, currentTime.get),
  http.get(`${REQUEST_BASE_URL}/feature_flags`, featureFlags.get),

  // 運用設定一覧取得
  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/operation_cycles`, operationCycles.get),

  // 運用設定新規登録
  http.post(`${REQUEST_BASE_URL}/operations/companies/:companyId/operation_cycles`, operationCycles.post),

  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/operation_cycle_names`, operationCycleNames.get),

  // 運用設定取得
  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/operation_cycles/:operationCycleId`, operationCycle.get),

  // 運用設定保存
  http.put(`${REQUEST_BASE_URL}/operations/companies/:companyId/operation_cycles/:operationCycleId`, operationCycle.put),

  // 問い合わせ先メールアドレス更新
  http.put(`${REQUEST_BASE_URL}/operations/companies/:companyId/operation_cycles/:operationCycleId/contact_email`, operationCycleContactEmail.put),

  // 運用設定非表示更新
  http.put(`${REQUEST_BASE_URL}/operations/companies/:companyId/operation_cycles/:operationCycleId/hidden`, operationCycleHidden.put),

  // ストレッチプラン一覧取得
  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/operation_cycles/:operationCycleId/stretch_plans`, operationCycleStretchPlans.get),

  // サーベイ一覧取得
  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/operation_cycles/:operationCycleId/surveys`, operationCycleSurveys.get),

  // 対象者が設定されているサーベイ一覧取得
  http.get(
    `${REQUEST_BASE_URL}/operations/companies/:companyId/operation_cycles/:operationCycleId/surveys_with_questionnaires`,
    operationCycleSurveysWithQuestionnaires.get
  ),

  // 研修一覧取得
  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/operation_cycles/:operationCycleId/trainings`, operationCycleTrainings.get),

  // 研修登録
  http.post(`${REQUEST_BASE_URL}/operations/companies/:companyId/operation_cycles/:operationCycleId/trainings`, operationCycleTrainings.post),

  // サーベイ詳細取得
  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/surveys/:surveyId`, survey.get),

  // サーベイ回答状況取得
  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/surveys/:surveyId/answer_status`, surveyAnswerStatus.get),

  // サーベイ実施設定取得
  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/surveys/:surveyId/config`, surveyConfig.get),

  // サーベイ追加設問取得
  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/surveys/:surveyId/additional_questions`, surveyAdditionalQuestions.get),

  // サーベイ追加設問設定
  http.post(`${REQUEST_BASE_URL}/operations/companies/:companyId/surveys/:surveyId/additional_questions`, surveyAdditionalQuestions.post),

  // サーベイ追加設問翻訳取得
  http.get(
    `${REQUEST_BASE_URL}/operations/companies/:companyId/surveys/:surveyId/additional_questions/translation`,
    surveyAdditionalQuestionsTranslation.get
  ),

  // サーベイ追加設問翻訳更新
  http.put(
    `${REQUEST_BASE_URL}/operations/companies/:companyId/surveys/:surveyId/additional_questions/translation`,
    surveyAdditionalQuestionsTranslation.put
  ),

  // サーベイメール設定取得
  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/surveys/:surveyId/mail_reminders`, surveyMailReminders.get),

  // サーベイ結果共有メール送信日時登録
  http.post(
    `${REQUEST_BASE_URL}/operations/companies/:companyId/surveys/:surveyId/result_notification_mail_schedule`,
    surveyResultNotificationMailSchedule.post
  ),

  // サーベイ対象者・回答者の一括設定ファイルアップロード
  http.post(
    `${REQUEST_BASE_URL}/operations/companies/:companyId/surveys/:surveyId/questionnaire_setting/csv_upload`,
    surveyQuestionnaireSettingCSVUpload.post
  ),

  // サーベイ対象者・回答者の一括設定実行
  http.post(
    `${REQUEST_BASE_URL}/operations/companies/:companyId/surveys/:surveyId/file_import_tasks/:fileImportTaskId/import`,
    surveyFileImportTaskImport.post
  ),

  // サーベイ対象者・回答者の一括設定ファイルアップロード進捗取得
  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/surveys/:surveyId/file_import_tasks/progress`, surveyFileImportTasksProgress.get),

  // サーベイ対象者・回答者の一括設定ファイルアップロード履歴取得
  http.get(
    `${REQUEST_BASE_URL}/operations/companies/:companyId/surveys/:surveyId/questionnaire_setting/upload_histories`,
    surveyQuestionnaireSettingUploadHistories.get
  ),

  // サーベイ閲覧者の一括設定ファイルアップロード
  http.post(`${REQUEST_BASE_URL}/operations/companies/:companyId/surveys/:surveyId/viewer_setting/csv_upload`, surveyViewerSettingCSVUpload.post),

  // サーベイ閲覧者の一括設定ファイルアップロード進捗取得
  http.get(
    `${REQUEST_BASE_URL}/operations/companies/:companyId/surveys/:surveyId/viewer_setting/file_import_tasks/progress`,
    surveyViewerFileImportTasksProgress.get
  ),

  // サーベイ閲覧者の一括設定実行
  http.post(
    `${REQUEST_BASE_URL}/operations/companies/:companyId/surveys/:surveyId/viewer_setting/file_import_tasks/:fileImportTaskId/import`,
    surveyViewerFileImportTaskImport.post
  ),

  // サーベイ回答者一覧取得
  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/surveys/:surveyId/questionnaire_answerers`, surveyQuestionnaireAnswerers.get),

  // サーベイ回答促進メール個別送信履歴取得
  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/surveys/:surveyId/individual_reminder_mails`, surveyIndividualReminderMails.get),

  // サーベイ回答促進メール個別送信API
  http.post(`${REQUEST_BASE_URL}/operations/companies/:companyId/surveys/:surveyId/individual_reminder_mails`, surveyIndividualReminderMails.post),

  // サーベイの対象者一覧確認
  http.get(
    `${REQUEST_BASE_URL}/operations/companies/:companyId/surveys/:surveyId/questionnaire_setting/answer_target_users`,
    surveyQuestionnaireSettingAnswerTargetUsers.get
  ),

  // ユーザー情報の取得
  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/company_users/:companyUserId`, companyUser.get),

  // 対象者に紐づく回答者情報の取得
  http.get(
    `${REQUEST_BASE_URL}/operations/companies/:companyId/surveys/:surveyId/questionnaire_setting/answer_target_users/:companyUserId/answerers`,
    surveyQuestionnaireSettingAnswerTargetUsersAnswerers.get
  ),

  // 対象者に回答者を紐づけ
  http.post(
    `${REQUEST_BASE_URL}/operations/companies/:companyId/surveys/:surveyId/questionnaire_setting/answer_target_users/:companyUserId/answerers`,
    surveyQuestionnaireSettingAnswerTargetUsersAnswerers.post
  ),

  // 対象者から回答者を削除
  http.delete(
    `${REQUEST_BASE_URL}/operations/companies/:companyId/surveys/:surveyId/questionnaire_setting/answer_target_users/:companyUserId/answerers`,
    surveyQuestionnaireSettingAnswerTargetUsersAnswerers.delete
  ),

  // 対象者に紐づく回答者候補一覧取得
  http.get(
    `${REQUEST_BASE_URL}/operations/companies/:companyId/surveys/:surveyId/questionnaire_setting/answer_target_users/:companyUserId/answerer_candidates`,
    surveyQuestionnaireSettingAnswerTargetUsersAnswererCandidates.get
  ),

  // 研修種別取得
  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/training_billing_types`, trainingBillingTypes.get),

  // 研修詳細取得
  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/trainings/:trainingId`, training.get),

  // 研修料金体系取得
  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/trainings/:trainingId/plan_service`, trainingPlanService.get),

  // 研修で利用可能なチケット
  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/trainings/:trainingId/available_tickets`, trainingAvailableTickets.get),

  // 研修実施設定取得
  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/trainings/:trainingId/config`, trainingConfig.get),

  // 研修実施日時更新
  http.put(`${REQUEST_BASE_URL}/operations/companies/:companyId/trainings/:trainingId/period`, trainingPeriod.put),

  // 研修参加予定者更新
  http.put(`${REQUEST_BASE_URL}/operations/companies/:companyId/trainings/:trainingId/expected_participants`, trainingExpectedParticipants.put),

  // 研修参加者の一括設定処理
  http.post(
    `${REQUEST_BASE_URL}/operations/companies/:companyId/trainings/:trainingId/file_import_tasks/:fileImportTasks/import`,
    trainingFileImportTaskImport.post
  ),

  // 研修参加者の一括設定ファイルアップロード
  http.post(
    `${REQUEST_BASE_URL}/operations/companies/:companyId/trainings/:trainingId/training_setting/csv_upload`,
    trainingTrainingSettingCSVUpload.post
  ),

  // 研修参加者の一括設定処理進捗取得
  http.get(
    `${REQUEST_BASE_URL}/operations/companies/:companyId/trainings/:trainingId/file_import_tasks/progress`,
    trainingFileImportTasksProgress.get
  ),

  // 研修参加者の一括設定ファイルアップロード履歴
  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/trainings/:trainingId/file_import_tasks`, trainingFileImportTasks.get),

  // ストレッチプラン詳細取得
  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/stretch_plans/:stretchPlanId`, stretchPlan.get),

  // ストレッチプラン実施設定取得
  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/stretch_plans/:stretchPlanId/config`, stretchPlanConfig.get),

  // ストレッチプラン対象者一括設定処理
  http.post(
    `${REQUEST_BASE_URL}/operations/companies/:companyId/stretch_plans/:stretchPlanId/file_import_tasks/:fileImportTaskId/import`,
    stretchPlanFileImportTaskImport.post
  ),

  // ストレッチプランメール設定取得
  http.get(
    `${REQUEST_BASE_URL}/operations/companies/:companyId/stretch_plans/:stretchPlanId/remind_mail_schedules`,
    stretchPlanRemindMailSchedules.get
  ),

  // ストレッチプラン対象者一括設定処理進捗取得
  http.get(
    `${REQUEST_BASE_URL}/operations/companies/:companyId/stretch_plans/:stretchPlanId/file_import_tasks/progress`,
    stretchPlanFileImportTasksProgress.get
  ),

  // ストレッチプラン対象者一括設定ファイルアップロード履歴
  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/stretch_plans/:stretchPlanId/file_import_tasks`, stretchPlanFileImportTasks.get),

  // ストレッチプラン対象者一括設定ファイルアップロード
  http.post(
    `${REQUEST_BASE_URL}/operations/companies/:companyId/stretch_plans/:stretchPlanId/target_users/csv_upload`,
    stretchPlanTargetUsersCSVUpload.post
  ),

  // ストレッチプラン振り返り入力依頼・促進メール送信日時一覧取得
  http.get(
    `${REQUEST_BASE_URL}/operations/companies/:companyId/stretch_plans/:stretchPlanId/progress_mail_schedules`,
    stretchPlanProgressMailSchedules.get
  ),

  // ストレッチプラン対象者一覧取得
  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/stretch_plans/:stretchPlanId/managed_users`, stretchPlanManagedUsers.get),

  // ストレッチプラン登録促進メール個別送信履歴取得
  http.get(
    `${REQUEST_BASE_URL}/operations/companies/:companyId/stretch_plans/:stretchPlanId/individual_remind_mails`,
    stretchPlanIndividualRemindMails.get
  ),

  // ストレッチプラン登録促進メール個別送信
  http.post(
    `${REQUEST_BASE_URL}/operations/companies/:companyId/stretch_plans/:stretchPlanId/individual_remind_mails`,
    stretchPlanIndividualRemindMails.post
  ),

  // メール設定取得
  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/operation_cycles/:operationCycleId/mail_message`, operationCycleMailMessage.get),

  // メール言語別更新
  http.put(
    `${REQUEST_BASE_URL}/operations/companies/:companyId/operation_cycles/:operationCycleId/mail_localized_content`,
    operationCycleMailLocalizedContent.put
  ),

  // メール設定取得
  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/operation_cycles/:operationCycleId/mail_content`, operationCycleMailContent.get),

  // メール設定更新
  http.put(`${REQUEST_BASE_URL}/operations/companies/:companyId/operation_cycles/:operationCycleId/mail_content`, operationCycleMailContent.put),
  http.put(
    `${REQUEST_BASE_URL}/operations/companies/:companyId/operation_cycles/:operationCycleId/mail_include_addressee`,
    operationCycleMailIncludeAddressee.put
  ),

  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/company_plans`, companyPlans.get),
  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/billing_services`, billingServices.get),

  // 企業メール設定取得
  http.get(`${ORG_REQUEST_BASE_URL}/companies/:companyId/services/:serviceId/company_mail_setting`, companyServiceCompanyMailSetting.get),

  // 企業メール設定保存
  http.post(`${ORG_REQUEST_BASE_URL}/companies/:companyId/services/:serviceId/company_mail_setting`, companyServiceCompanyMailSetting.post),

  // メール送信対象取得
  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/send_mail_user_settings`, sendMailUserSettings.get),

  // メール送信対象更新PI
  http.post(`${REQUEST_BASE_URL}/operations/companies/:companyId/send_mail_user_settings`, sendMailUserSettings.post),

  // 追加設問の開示を設定
  http.post(
    `${ORG_REQUEST_BASE_URL}/companies/:companyId/surveys/:surveyId/additional_question_result_disclosable`,
    additionalQuestionResultDisclosable.post
  ),

  // 初期設計取得API
  http.get(`${REQUEST_BASE_URL}/operations/companies/:companyId/first_planning`, firstPlanning.get),

  // 初期設計資料登録API
  http.post(`${REQUEST_BASE_URL}/operations/companies/:companyId/first_planning`, firstPlanning.post),

  // 共有用URL一覧CSVダウンロードURLの取得API
  http.get(
    `${REQUEST_BASE_URL}/operations/companies/:companyId/trainings/:trainingId/shared_url_list/file_download_url`,
    trainingSharedUrlListFileDownloadUrl.get
  ),
];
