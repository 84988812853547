import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const get: GetResponseResolver = async () => {
  return HttpResponse.json({
    name: '今井恭平',
    email: 'imai@example.com',
  });
};

export const companyUser = {
  get,
};
