import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';
import { SURVEY } from '../constants/resourceId';

const SYSTEM_ERROR = '500';

export const get: GetResponseResolver = ({ params }) => {
  if (params.companyId === SYSTEM_ERROR) {
    return HttpResponse.json({}, { status: 500 });
  }

  if (params.surveyId === SURVEY.SETTING_INCOMPLETE) {
    return HttpResponse.json(
      {
        survey: {
          answerTargetUsersCount: 0,
          answerersCount: 0,
          bossAdditionalQuestionsCount: 5,
          staffAdditionalQuestionsCount: 0,
          limitedAt: null,
          requestMailStartedAt: '2053-03-06T10:00:00+09:00',
          reminderMailCount: 2,
          startRemainingDays: 25,
          startedAt: '2053-03-06T10:00:00+09:00',
          endedAt: '2053-03-10T18:00:00+09:00',
          contactEmail: null,
          isConfirmed: false,
          existsDataSheetSummaryZip: false,
          existsAdditionalQuestionResultCsv: false,
          hasDiscloseAdditionalQuestion: false,
          resultNotificationMailSchedule: {
            sendAt: null,
            hasDiscard: false,
          },
        },
      },
      { status: 200 }
    );
  }

  if (params.surveyId === SURVEY.COMPLETE) {
    return HttpResponse.json(
      {
        survey: {
          answerTargetUsersCount: 2,
          answerersCount: 1,
          bossAdditionalQuestionsCount: 5,
          staffAdditionalQuestionsCount: 0,
          limitedAt: null,
          requestMailStartedAt: '2023-03-06T10:00:00+09:00',
          reminderMailCount: 10,
          startRemainingDays: 13,
          startedAt: '2023-03-06T10:00:00+09:00',
          endedAt: '2023-03-10T18:00:00+09:00',
          contactEmail: 'test@example.com',
          isConfirmed: true,
          existsDataSheetSummaryZip: true,
          existsAdditionalQuestionResultCsv: true,
          hasDiscloseAdditionalQuestion: true,
          resultNotificationMailSchedule: {
            sendAt: '2023-03-11T18:00:00+09:00',
            hasDiscard: false,
          },
        },
      },
      { status: 200 }
    );
  }

  if (params.surveyId === SURVEY.CALCULATED_UNEXISTS_SUMMARY_ZIP) {
    return HttpResponse.json(
      {
        survey: {
          answerTargetUsersCount: 2,
          answerersCount: 1,
          bossAdditionalQuestionsCount: 5,
          staffAdditionalQuestionsCount: 0,
          limitedAt: null,
          requestMailStartedAt: '2023-03-06T10:00:00+09:00',
          reminderMailCount: 10,
          startRemainingDays: 13,
          startedAt: '2023-03-06T10:00:00+09:00',
          endedAt: '2023-03-10T18:00:00+09:00',
          contactEmail: 'test@example.com',
          isConfirmed: true,
          existsDataSheetSummaryZip: false,
          existsAdditionalQuestionResultCsv: false,
          hasDiscloseAdditionalQuestion: true,
          resultNotificationMailSchedule: {
            sendAt: '2023-03-11T18:00:00+09:00',
            hasDiscard: false,
          },
        },
      },
      { status: 200 }
    );
  }

  if (params.surveyId === SURVEY.CALCULATED_EXISTS_SUMMARY_ZIP) {
    return HttpResponse.json(
      {
        survey: {
          answerTargetUsersCount: 2,
          answerersCount: 1,
          bossAdditionalQuestionsCount: 5,
          staffAdditionalQuestionsCount: 0,
          limitedAt: null,
          requestMailStartedAt: '2023-03-06T10:00:00+09:00',
          reminderMailCount: 10,
          startRemainingDays: 13,
          startedAt: '2023-03-06T10:00:00+09:00',
          endedAt: '2023-03-10T18:00:00+09:00',
          contactEmail: 'test@example.com',
          isConfirmed: true,
          existsDataSheetSummaryZip: true,
          existsAdditionalQuestionResultCsv: false,
          hasDiscloseAdditionalQuestion: true,
          resultNotificationMailSchedule: {
            sendAt: '2023-03-11T18:00:00+09:00',
            hasDiscard: false,
          },
        },
      },
      { status: 200 }
    );
  }

  if (params.surveyId === SURVEY.SETTING_INPROGRESS_FOR_CONFIG_VALIDATION_ERROR) {
    return HttpResponse.json(
      {
        survey: {
          answerTargetUsersCount: 2,
          answerersCount: 1,
          bossAdditionalQuestionsCount: 5,
          staffAdditionalQuestionsCount: 0,
          limitedAt: null,
          requestMailStartedAt: '2023-03-06T10:00:00+09:00',
          reminderMailCount: 2,
          startRemainingDays: 13,
          startedAt: '2023-03-06T10:00:00+09:00',
          endedAt: '2023-03-10T18:00:00+09:00',
          contactEmail: 'test@example.com',
          isConfirmed: true,
          existsDataSheetSummaryZip: false,
          existsAdditionalQuestionResultCsv: false,
          hasDiscloseAdditionalQuestion: false,
          resultNotificationMailSchedule: {
            sendAt: '2023-03-09T18:00:00+09:00',
            hasDiscard: false,
          },
        },
      },
      { status: 200 }
    );
  }

  if (params.surveyId === SURVEY.SETTING_INPROGRESS_FOR_CONFIG_VALIDATION_WARNING) {
    return HttpResponse.json(
      {
        survey: {
          answerTargetUsersCount: 0,
          answerersCount: 0,
          bossAdditionalQuestionsCount: 5,
          staffAdditionalQuestionsCount: 0,
          limitedAt: null,
          requestMailStartedAt: '2053-03-06T10:00:00+09:00',
          reminderMailCount: 2,
          startRemainingDays: 25,
          startedAt: '2053-03-06T10:00:00+09:00',
          endedAt: '2053-03-10T18:00:00+09:00',
          contactEmail: null,
          isConfirmed: false,
          existsDataSheetSummaryZip: false,
          existsAdditionalQuestionResultCsv: false,
          hasDiscloseAdditionalQuestion: false,
          resultNotificationMailSchedule: {
            sendAt: '2053-03-09T18:00:00+09:00',
            hasDiscard: false,
          },
        },
      },
      { status: 200 }
    );
  }

  if (params.surveyId === SURVEY.SETTING_INPROGRESS_FOR_CONFIG_DISCARD_ERROR) {
    return HttpResponse.json(
      {
        survey: {
          answerTargetUsersCount: 2,
          answerersCount: 1,
          bossAdditionalQuestionsCount: 5,
          staffAdditionalQuestionsCount: 0,
          limitedAt: null,
          requestMailStartedAt: '2023-03-06T10:00:00+09:00',
          reminderMailCount: 10,
          startRemainingDays: 13,
          startedAt: '2023-03-06T10:00:00+09:00',
          endedAt: '2023-03-10T18:00:00+09:00',
          contactEmail: 'test@example.com',
          isConfirmed: true,
          existsDataSheetSummaryZip: false,
          existsAdditionalQuestionResultCsv: false,
          hasDiscloseAdditionalQuestion: true,
          resultNotificationMailSchedule: {
            sendAt: '2023-03-11T18:00:00+09:00',
            hasDiscard: true,
          },
        },
      },
      { status: 200 }
    );
  }

  if (params.surveyId === SURVEY.PASSED_START_TIME) {
    return HttpResponse.json(
      {
        survey: {
          answerTargetUsersCount: 0,
          answerersCount: 0,
          bossAdditionalQuestionsCount: 5,
          staffAdditionalQuestionsCount: 0,
          limitedAt: null,
          requestMailStartedAt: '2053-03-06T10:00:00+09:00',
          reminderMailCount: 2,
          startRemainingDays: 0,
          startedAt: '2023-03-06T10:00:00+09:00',
          endedAt: '2053-03-10T18:00:00+09:00',
          contactEmail: null,
          isConfirmed: false,
          existsDataSheetSummaryZip: false,
          existsAdditionalQuestionResultCsv: false,
          hasDiscloseAdditionalQuestion: false,
          resultNotificationMailSchedule: {
            sendAt: null,
            hasDiscard: false,
          },
        },
      },
      { status: 200 }
    );
  }

  return HttpResponse.json(
    {
      survey: {
        answerTargetUsersCount: 2,
        answerersCount: 1,
        bossAdditionalQuestionsCount: 5,
        staffAdditionalQuestionsCount: 0,
        limitedAt: null,
        requestMailStartedAt: '2023-03-06T10:00:00+09:00',
        reminderMailCount: 10,
        startRemainingDays: 13,
        startedAt: '2023-03-06T10:00:00+09:00',
        endedAt: '2023-03-10T18:00:00+09:00',
        contactEmail: 'test@example.com',
        isConfirmed: true,
        existsDataSheetSummaryZip: false,
        existsAdditionalQuestionResultCsv: false,
        hasDiscloseAdditionalQuestion: false,
        resultNotificationMailSchedule: {
          sendAt: null,
          hasDiscard: false,
        },
      },
    },
    { status: 200 }
  );
};

export const surveyConfig = {
  get,
};
