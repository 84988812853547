<template>
  <div id="app">
    <RouterView v-if="!error" v-slot="{ Component }">
      <Suspense timeout="0">
        <component :is="Component" />
      </Suspense>
    </RouterView>

    <NotFound v-else-if="error?.response?.status === 404" />
    <SystemError v-else />
    <PageLoading :isLoading="isLoading" />
  </div>
</template>

<script setup lang="ts">
import { ref, onErrorCaptured } from 'vue';
import NotFound from './views/error/NotFound.vue';
import SystemError from './views/error/SystemError.vue';
import PageLoading from '~operation/components/common/PageLoading.vue';
import { usePage } from '~operation/composables/usePage';
import { useRouter } from 'vue-router';
import { AxiosError } from 'axios';
import { chunkFailedRegExp, dynamicallyImportedModuleRegExp, unableToPreloadCSSRegExp } from '~operation/constants/errorReqExp';

const { isLoading } = usePage();

const router = useRouter();
router.onError((error) => {
  if (chunkFailedRegExp.test(error.message) || dynamicallyImportedModuleRegExp.test(error.message)) {
    window.location.reload();
  }
});

const error = ref<AxiosError>();

onErrorCaptured((e: Error | AxiosError) => {
  // CSSのpreloadに失敗したらリロードする
  if (unableToPreloadCSSRegExp.test(e.message)) {
    window.location.reload();
  }

  if (!('response' in e)) {
    return;
  }

  if (e.response?.status === 409) {
    setTimeout(() => {
      router.push({ name: 'top_page' });
    }, 3000);

    return;
  }

  if (e.config?.data?.shouldShowErrorPage) {
    error.value = e;
  }
});
</script>

<style lang="scss" module></style>
