import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const SYSTEM_ERROR = '500';

export const get: GetResponseResolver = ({ params }) => {
  if (params.companyId === SYSTEM_ERROR) {
    return HttpResponse.json({}, { status: 500 });
  }

  return HttpResponse.json(
    {
      totalCount: 2,
      stretchPlanSchedules: [
        {
          startedAt: '2023-02-17T00:00:00+09:00',
          endedAt: '2023-02-26T23:59:59+09:00',
          stretchPlanMailSchedules: [
            {
              id: 1,
              isRemind: false,
              sendAt: '2023-02-26T09:00:00+09:00',
              status: '送信予定',
            },
            {
              id: 2,
              isRemind: true,
              sendAt: '2023-02-27T09:00:00+09:00',
              status: '送信予定',
            },
          ],
        },
        {
          startedAt: '2023-02-27T00:00:00+09:00',
          endedAt: '2053-03-05T23:59:59+09:00',
          stretchPlanMailSchedules: [
            {
              id: 3,
              isRemind: false,
              sendAt: '2053-03-04T09:00:00+09:00',
              status: '送信予定',
            },
            {
              id: 4,
              isRemind: true,
              sendAt: '2053-03-05T09:00:00+09:00',
              status: '送信予定',
            },
          ],
        },
      ],
    },
    { status: 200 }
  );
};

export const stretchPlanProgressMailSchedules = {
  get,
};
