import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const EMPTY = '4';
const EMPTY_LM = '10004';

export const get: GetResponseResolver = ({ params }) => {
  if (params.companyId === EMPTY || params.companyId === EMPTY_LM) {
    return HttpResponse.json(
      {
        unusedTicketsCount: 0,
        pendingTicketsCount: 0,
        availableTicketsCount: 0,
        ticketsByExpiration: [],
      },
      { status: 200 }
    );
  }

  return HttpResponse.json(
    {
      unusedTicketsCount: 1180,
      pendingTicketsCount: 1000,
      availableTicketsCount: 180,
      ticketsByExpiration: [
        {
          expireDate: '2024/05/21',
          count: 100,
        },
        {
          expireDate: '2024/09/24',
          count: 80,
        },
      ],
    },
    { status: 200 }
  );
};

export const tickets = {
  get,
};
