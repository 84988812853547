export const PAGE_TITLE = {
  DEFAULT_TITLE: 'Stretch Cloud',
  ORGANIZATIONS: '契約一覧',
  TOP_PAGE: 'トップページ',
  OPERATION_CYCLE_DETAIL: '運用設定詳細',
  OPERATION_CYCLE_CREATE: '運用設定作成',
  OPERATION_CYCLE_EDIT: '運用設定編集',
  SURVEY_DETAIL: 'サーベイ詳細',
  TRAINING_DETAIL: '研修詳細',
  STRETCH_PLAN_DETAIL: 'ストレッチプラン詳細',
  REQUEST_ANSWER_MAIL: 'サーベイ回答依頼メール設定',
  REMIND_ANSWER_MAIL: 'サーベイ回答促進メール設定',
  REMIND_ANSWER_MAIL_LIST: 'サーベイ回答促進メール一覧',
  ADDITIONAL_QUESTIONS: 'サーベイ追加設問設定',
  ADDITIONAL_QUESTIONS_TRANSLATION: 'サーベイ追加設問翻訳',
  BULK_QUESTIONNAIRE_SETTING: 'サーベイ対象者・回答者一括設定',
  SURVEY_RESEND_REMIND_MAIL: 'サーベイ回答促進メール再送設定',
  SURVEY_ANSWER_TARGET_USERS: '未定', // TODO ページタイトルが決まったら修正する
  SURVEY_ANSWER_TARGET_USER: '未定', // TODO ページタイトルが決まったら修正する
  BULK_TRAINING_SETTINGS: '研修参加者一括設定',
  STRETCH_PLAN_REQUEST_MAIL: 'ストレッチプラン登録依頼メール設定',
  REMIND_MAIL_LIST: 'ストレッチプラン登録促進メール送信一覧',
  STRETCH_PLAN_REMIND_MAIL: 'ストレッチプラン登録促進メール設定',
  STRETCH_PLAN_PROGRESS_REQUEST_MAIL: 'ストレッチプラン振り返り入力依頼メール設定',
  STRETCH_PLAN_PROGRESS_REMIND_MAIL: 'ストレッチプラン振り返り入力促進メール設定',
  BULK_STRETCH_PLAN_SETTINGS: 'ストレッチプラン対象者一括設定',
  STRETCH_PLAN_PROGRESS_INPUT_MAIL_LIST: 'ストレッチプラン振り返り依頼メール送信一覧',
  STRETCH_PLAN_RESEND_REMIND_MAIL: 'ストレッチプラン登録促進メール再送設定',
  USERS_SETTINGS_PAGE: 'ユーザー設定',
  USERS_SETTINGS_BULK_SETTING_PAGE: 'ユーザーの一括設定',
  USERS_SETTINGS_MAIL_SCHEDULES_PAGE: 'ユーザー設定メール送信予約設定',
  USERS_SETTINGS_RESEND_INVITATION_MAIL_PAGE: 'アカウント発行メール再送設定',
  COMPANY_MAIL_SETTING: 'システムメール設定',
  SERVICE_USAGE: 'サービス利用状況',
  ERROR: 'エラー',
  USERS_DETAIL_PAGE: 'ユーザー詳細',
} as const;
