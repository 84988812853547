import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

export const get: GetResponseResolver = () => {
  return HttpResponse.json(
    {
      uploadHistories: [
        {
          id: 2,
          startedAt: '2023-02-10T17:58:47+09:00',
          executeUserName: 'LM社員',
          status: 'validate_failed',
          fileDownloadable: true,
          errorFileDownloadable: true,
        },
        {
          id: 1,
          startedAt: '2023-02-10T17:56:36+09:00',
          executeUserName: 'LM社員',
          status: 'import_finished',
          fileDownloadable: true,
          errorFileDownloadable: false,
        },
      ],
    },
    { status: 200 }
  );
};

export const stretchPlanFileImportTasks = {
  get,
};
