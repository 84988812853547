import { HttpResponse } from 'msw';
import type { PutResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const SYSTEM_ERROR = '500';

export const put: PutResponseResolver = ({ params }) => {
  if (params.companyId === SYSTEM_ERROR) {
    return HttpResponse.json({}, { status: 500 });
  }

  return HttpResponse.json({}, { status: 200 });
};

export const operationCycleHidden = {
  put,
};
