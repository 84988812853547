import { HttpResponse } from 'msw';
import type { PostResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const SYSTEM_ERROR = '500';

export const post: PostResponseResolver = ({ params }) => {
  if (params.companyId === SYSTEM_ERROR) {
    return HttpResponse.json({}, { status: 500 });
  }

  return HttpResponse.json(
    {
      startedAt: '2023-02-22T18:46:59+09:00',
      email: 'test@example.com',
    },
    { status: 200 }
  );
};

export const stretchPlanFileImportTaskImport = {
  post,
};
