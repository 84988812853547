import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const SYSTEM_ERROR = '500';

const get: GetResponseResolver = async ({ params }) => {
  await new Promise((resolve) => setTimeout(resolve, 500));

  if (params.companyId === SYSTEM_ERROR) {
    return HttpResponse.json({}, { status: 500 });
  }

  return HttpResponse.json(
    {
      status: 'success',
      progress: 100,
      startedAt: '2023-02-22T15:14:24+09:00',
      email: 'sample@example.com',
      errorFileId: null,
    },
    { status: 200 }
  );
};

export const surveyFileImportTasksProgress = { get };
