import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';
import { STRETCH_PLAN } from '../constants/resourceId';

const SYSTEM_ERROR = '500';

export const get: GetResponseResolver = ({ params }) => {
  if (params.companyId === SYSTEM_ERROR) {
    return HttpResponse.json({}, { status: 500 });
  }

  if (params.stretchPlanId === STRETCH_PLAN.SETTING_INCOMPLETE) {
    return HttpResponse.json(
      {
        stretchPlan: {
          id: 2,
          status: '設定未完了',
          targetUserCount: 0,
          startedAt: '2050-01-01T00:00:00+09:00',
          endedAt: '2051-01-01T23:59:59+09:00',
          limitAt: null,
          requestMailSendAt: null,
          contactEmail: null,
          progressFrequency: null,
          progressWday: null,
          remindRelativeDays: null,
          sendRemindMailTime: null,
          progressRemindMailSettings: [],
          isConfirmed: false,
          isConfirmable: false,
          isTodayStart: false,
          nextStretchPlanSchedule: null,
          firstStretchPlanProgressRequestMailDate: null,
          firstStretchPlanProgressRemindMailSchedule: null,
          finishRemainingDays: 30,
        },
      },
      { status: 200 }
    );
  }

  if (params.stretchPlanId === STRETCH_PLAN.IN_PROGRESS_CASE) {
    return HttpResponse.json(
      {
        stretchPlan: {
          id: 3,
          status: '実施中',
          targetUserCount: 1,
          startedAt: '2023-02-13T00:00:00+09:00',
          endedAt: '2023-03-05T23:59:59+09:00',
          limitAt: '2023-02-16T00:00:00+09:00',
          requestMailSendAt: '2023-02-14T02:00:00+09:00',
          contactEmail: 'test@example.com',
          progressFrequency: 1,
          progressWday: 0,
          remindRelativeDays: 0,
          sendRemindMailTime: '09:00',
          progressRemindMailSettings: [
            {
              relativeDays: 1,
              sendTime: '09:00',
            },
          ],
          isConfirmed: true,
          isConfirmable: false,
          isTodayStart: false,
          nextStretchPlanSchedule: {
            startedAt: '2023-02-17T00:00:00+09:00',
            endedAt: '2023-02-26T23:59:59+09:00',
          },
          firstStretchPlanProgressRequestMailDate: '2023-02-26',
          firstStretchPlanProgressRemindMailSchedule: {
            sendDate: '2023-02-27',
            sendTime: '09:00',
          },
          finishRemainingDays: 30,
        },
      },
      { status: 200 }
    );
  }

  if (params.stretchPlanId === STRETCH_PLAN.PASSED_START_TIME) {
    return HttpResponse.json(
      {
        stretchPlan: {
          id: 4,
          status: '設定未完了',
          targetUserCount: 0,
          startedAt: '2020-01-01T00:00:00+09:00',
          endedAt: '2021-01-01T23:59:59+09:00',
          limitAt: null,
          requestMailSendAt: null,
          contactEmail: null,
          progressFrequency: null,
          progressWday: null,
          remindRelativeDays: null,
          sendRemindMailTime: null,
          progressRemindMailSettings: [],
          isConfirmed: false,
          isConfirmable: false,
          isTodayStart: false,
          nextStretchPlanSchedule: null,
          firstStretchPlanProgressRequestMailDate: null,
          firstStretchPlanProgressRemindMailSchedule: null,
          finishRemainingDays: 30,
        },
      },
      { status: 200 }
    );
  }

  return HttpResponse.json(
    {
      stretchPlan: {
        id: 1,
        status: '設定完了',
        targetUserCount: 1,
        startedAt: '2053-03-01T00:00:00+09:00',
        endedAt: '2053-03-31T23:59:59+09:00',
        limitAt: '2053-03-01T09:00:00+09:00',
        requestMailSendAt: '2053-03-01T08:00:00+09:00',
        contactEmail: 'test@example.com',
        progressFrequency: 1,
        progressWday: 1,
        remindRelativeDays: 0,
        sendRemindMailTime: '09:00',
        progressRemindMailSettings: [
          {
            relativeDays: 1,
            sendTime: '09:00',
          },
        ],
        isConfirmed: true,
        isConfirmable: false,
        isTodayStart: false,
        nextStretchPlanSchedule: {
          startedAt: '2053-03-02T00:00:00+09:00',
          endedAt: '2053-03-13T23:59:59+09:00',
        },
        firstStretchPlanProgressRequestMailDate: '2053-03-13',
        firstStretchPlanProgressRemindMailSchedule: {
          sendDate: '2053-03-14',
          sendTime: '09:00',
        },
        finishRemainingDays: 30,
      },
    },
    { status: 200 }
  );
};

export const stretchPlanConfig = {
  get,
};
