import { HttpResponse } from 'msw';
import type { PostResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const ERROR_400 = '400';
const SURVEY_REQUIRE_CONFIRMATION_MODAL = '2';

const post: PostResponseResolver = ({ params }) => {
  if (params.surveyId === ERROR_400) {
    return HttpResponse.json(
      {
        code: 'validation_error',
        message: 'バリデーションに失敗しました',
        validationErrors: [
          {
            field: 'file',
            messages: ['1行目の項目と2行目以降の値の組み合わせが正しくありません。1行目の項目は変更せず、新しい列も追加しないでください。'],
          },
        ],
      },
      { status: 400 }
    );
  }

  if (params.surveyId === SURVEY_REQUIRE_CONFIRMATION_MODAL) {
    return HttpResponse.json(
      {
        startedAt: null,
        fileImportTaskId: 1,
        requestNotificationConfirmation: true,
      },
      { status: 200 }
    );
  }

  return HttpResponse.json(
    {
      startedAt: '2021-08-06T17:15:46+09:00',
      fileImportTaskId: 1,
      requestNotificationConfirmation: false,
    },
    { status: 200 }
  );
};

export const surveyViewerSettingCSVUpload = { post };
