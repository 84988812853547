import { type RouteRecordRaw } from 'vue-router';

export const usersRoutes: RouteRecordRaw[] = [
  {
    // ユーザー設定
    path: '/companies/:companyId/users',
    redirect: { name: 'top_page' },
  },
  {
    // ユーザーの一括設定
    path: '/companies/:companyId/users/bulk-setting',
    redirect: { name: 'top_page' },
  },
  {
    // メール送信予約
    path: '/companies/:companyId/users/mail-schedules',
    redirect: { name: 'top_page' },
  },
  {
    // アカウント発行メール再送
    path: '/companies/:companyId/users/resend-invitation-mail',
    redirect: { name: 'top_page' },
  },
  {
    // ユーザー詳細
    path: '/companies/:companyId/users/:userId',
    redirect: { name: 'top_page' },
  },
];
