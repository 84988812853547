import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

export const get: GetResponseResolver = async () => {
  const csvData = '"hello", "csv", "file"';
  const blob = new Blob([csvData], { type: 'application/csv' });
  const fileDownloadUrl = URL.createObjectURL(blob);

  return HttpResponse.json(
    {
      fileDownloadUrl,
    },
    {
      status: 200,
    }
  );
};

export const trainingSharedUrlListFileDownloadUrl = { get };
