import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const EMPTY_CASE = '2';
const SYSTEM_ERROR = '500';

export const get: GetResponseResolver = ({ params }) => {
  if (params.companyId === SYSTEM_ERROR) {
    return HttpResponse.json({}, { status: 500 });
  }

  if (params.operationCycleId === EMPTY_CASE) {
    return HttpResponse.json(
      {
        totalStretchPlansCount: 0,
        stretchPlans: [],
      },
      { status: 200 }
    );
  }

  return HttpResponse.json(
    {
      totalStretchPlansCount: 2,
      stretchPlans: [
        {
          id: 4,
          startedAt: '2020-01-01T00:00:00+09:00',
          endedAt: '2021-01-01T23:59:59+09:00',
          progressFrequency: 1,
          progressWday: 1,
          targetUsersCount: 1,
          status: '設定完了',
        },
        {
          id: 3,
          startedAt: '2023-02-13T00:00:00+09:00',
          endedAt: '2023-03-05T23:59:59+09:00',
          progressFrequency: 1,
          progressWday: 0,
          targetUsersCount: 1,
          status: '実施中',
        },
        {
          id: 2,
          startedAt: '2050-01-01T00:00:00+09:00',
          endedAt: '2051-01-01T23:59:59+09:00',
          progressFrequency: null,
          progressWday: null,
          targetUsersCount: 0,
          status: '設定未完了',
        },
        {
          id: 1,
          startedAt: '2023-03-01T00:00:00+09:00',
          endedAt: '2023-03-31T23:59:59+09:00',
          progressFrequency: 1,
          progressWday: 1,
          targetUsersCount: 1,
          status: '設定完了',
        },
      ],
    },
    { status: 200 }
  );
};

export const operationCycleStretchPlans = {
  get,
};
