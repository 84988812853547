import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const RUNNING_TASK_CASE = '3';
let progress = 0;
let progressCount = 0;

export const get: GetResponseResolver = ({ params }) => {
  if (params.companyId === RUNNING_TASK_CASE) {
    if (progressCount < 4) {
      progress = 50;
    } else {
      progress = 100;
    }

    progressCount += 1;
    if (progressCount >= 5) {
      progressCount = 0;
    }

    return HttpResponse.json(
      {
        status: progress >= 100 ? 'success' : 'processing',
        progress: progress,
        startedAt: '2023-01-01T00:00:00.000Z',
      },
      { status: 200 }
    );
  }

  return HttpResponse.json(
    {
      status: 'success',
      progress: 0,
      startedAt: '',
    },
    { status: 200 }
  );
};

export const runningTasksProgress = {
  get,
};
