<template>
  <div class="error-frame">
    <div class="error-frame-inner">
      <div class="error-page">
        <div class="error-image">
          <img :src="errorImage" class="error-img" />
        </div>
        <div class="error-info">
          <div class="error-contents">
            <slot name="contents" />
          </div>
          <div class="error-reason">
            <slot name="reason" />
          </div>
          <div class="error-description">
            <slot name="description" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'ErrorFrame',
  props: {
    errorImage: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
@use '~operation/assets/stylesheets/mixins.scss';
@use '~operation/assets/stylesheets/vars.scss';

.error-frame {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 224px;

  .error-frame-inner {
    font-size: 14px;

    .error-page {
      background-color: vars.$BACKGROUND_GRAY;
      color: vars.$TEXT_BLACK;
      height: 100%;
      letter-spacing: 2px;
      width: 100%;
    }

    .error-image {
      margin: 0 auto;
      position: relative;
      text-align: center;
      width: 250px;
    }

    .error-img {
      height: 150px;
      margin-bottom: 10px;
    }

    .error-info {
      .error-contents {
        font-size: 12px;
        text-align: center;
        color: vars.$basicTextColor;
        font-weight: bold;
        letter-spacing: 1.4px;
        line-height: 7.14;
      }

      .error-reason {
        font-size: 20px;
        font-weight: bold;
        margin-top: -10px;
        text-align: center;
      }

      .error-description {
        font-size: 12px;
        line-height: 200%;
        margin-top: 15px;
        text-align: center;
      }
    }
  }
}
</style>
