import { HttpResponse, delay } from 'msw';
import type { GetResponseResolver, PostResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const INCOMPLETE = '2';
const LM_INCOMPLETE = '10002';

const HIDDEN = '3';

const get: GetResponseResolver = async ({ params }) => {
  if ([LM_INCOMPLETE, INCOMPLETE].includes(params.companyId)) {
    return HttpResponse.json(
      {
        firstPlanning: {
          fileName: null,
          firstUploadDate: null,
          lastUploadDate: null,
        },
        status: 'incomplete',
      },
      { status: 200 }
    );
  } else if (params.companyId === HIDDEN) {
    return HttpResponse.json(
      {
        firstPlanning: null,
        status: 'hidden',
      },
      { status: 200 }
    );
  }

  return HttpResponse.json(
    {
      firstPlanning: {
        fileName: '【施策全体設計書】株式会社KAME御中_19941110.pdf',
        firstUploadDate: '1993-02-10T11:20:00+09:00',
        lastUploadDate: '1994-11-10T18:20:00+09:00',
      },
      status: 'complete',
    },
    { status: 200 }
  );
};

const post: PostResponseResolver = async () => {
  await delay(500);

  return HttpResponse.json(
    {
      firstPlanning: {
        fileName: '【施策全体設計書】株式会社KAME御中_20101225.pdf',
        firstUploadDate: '1993-02-10T11:20:00+09:00',
        lastUploadDate: '2010-12-25T23:10:00+09:00',
      },
      status: 'complete',
    },
    { status: 200 }
  );
};

export const firstPlanning = {
  get,
  post,
};
