import { HttpResponse } from 'msw';
import type { PutResponseResolver } from '~operation/mocks/types/RestResponseResolver';

export const put: PutResponseResolver = () => {
  return HttpResponse.json({}, { status: 200 });
};

export const operationCycleMailLocalizedContent = {
  put,
};
