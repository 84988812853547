import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

export const get: GetResponseResolver = () => {
  return HttpResponse.json(
    {
      ticketsByExpiration: [
        {
          expireDate: '2024/05/21', // 有効期限
          count: 30, // 枚数
        },
        {
          expireDate: '2024/09/24',
          count: 30,
        },
      ],
    },
    { status: 200 }
  );
};

export const trainingAvailableTickets = {
  get,
};
