import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const RUNNING_TASK_CASE = '3';

export const get: GetResponseResolver = ({ params }) => {
  if (params.companyId === RUNNING_TASK_CASE) {
    return HttpResponse.json(
      {
        running: true,
        type: 'common_masters',
        progressPath: `operations/companies/${params.companyId}/common_masters/sync_histories/progress`,
      },
      { status: 200 }
    );
  }

  return HttpResponse.json(
    {
      running: false,
      type: null,
      progressPath: null,
    },
    { status: 200 }
  );
};

export const runningTasks = {
  get,
};
