import { reactive } from 'vue';
import type { FeatureFlags } from '~operation/types/api/featureFlags';

const featureFlagData: FeatureFlags = reactive<FeatureFlags>({});

const updateFeatureFlag = (featureFlagValues: FeatureFlags) => {
  Object.entries(featureFlagValues).forEach(([key, value]) => {
    featureFlagData[key] = value;
  });
};

export const useFeatureFlag = () => {
  return {
    featureFlagData,
    updateFeatureFlag,
  };
};
