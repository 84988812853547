import { HttpResponse } from 'msw';
import type { PostResponseResolver } from '~operation/mocks/types/RestResponseResolver';

export const post: PostResponseResolver = () => {
  return HttpResponse.json(
    {
      send_at: '1994-11-10T18:00:00+09:00',
    },
    { status: 200 }
  );
};

export const surveyResultNotificationMailSchedule = {
  post,
};
