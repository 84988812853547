export function load(url: string) {
  return new Promise<void>((resolve, reject) => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;

    document.head.appendChild(script);

    script.onload = () => resolve();
    script.onerror = (e) => reject(e);
  });
}
