import { HttpResponse } from 'msw';
import type { PostResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const ERROR_400 = '400';
const NEW = '1';
const TICKET_SHORTFALL = '1000';
const TICKET_SHORTFALL_WITH_GC = '1001';

const post: PostResponseResolver = ({ params }) => {
  if (params.trainingId === ERROR_400) {
    return HttpResponse.json(
      {
        code: 'validation_error',
        message: 'バリデーションに失敗しました',
        validationErrors: [
          {
            field: 'file',
            messages: ['1行目の項目と2行目以降の値の組み合わせが正しくありません。1行目の項目は変更せず、新しい列も追加しないでください。'],
          },
        ],
      },
      { status: 400 }
    );
  }

  if (params.trainingId === NEW) {
    return HttpResponse.json(
      {
        fileImportTaskId: 30,
        trainingParticipants: {
          before: 0,
          after: 28,
          add: 28,
          delete: 0,
          maxCapacity: 29,
        },
        tickets: {
          before: 0,
          after: 58,
          availableCount: 0,
        },
      },
      { status: 200 }
    );
  }

  if (params.trainingId === TICKET_SHORTFALL) {
    return HttpResponse.json(
      {
        fileImportTaskId: 30,
        trainingParticipants: {
          before: 0,
          after: 1000,
          add: 1000,
          delete: 0,
          maxCapacity: 29,
        },
        tickets: {
          before: 0,
          after: 1,
          availableCount: 0,
        },
      },
      { status: 200 }
    );
  }

  if (params.trainingId === TICKET_SHORTFALL_WITH_GC) {
    return HttpResponse.json(
      {
        fileImportTaskId: 30,
        trainingParticipants: {
          before: 0,
          after: 1000,
          add: 1000,
          delete: 0,
          maxCapacity: 29,
        },
        tickets: {
          before: 0,
          after: 1,
          availableCount: 0,
        },
      },
      { status: 200 }
    );
  }

  return HttpResponse.json(
    {
      fileImportTaskId: 30,
      trainingParticipants: {
        before: 26,
        after: 29,
        add: 4,
        delete: 1,
        maxCapacity: 29,
      },
      tickets: {
        before: 52,
        after: 58,
        availableCount: 1,
      },
    },
    { status: 200 }
  );
};
export const trainingTrainingSettingCSVUpload = { post };
