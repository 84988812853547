import { type RouteRecordRaw } from 'vue-router';
import type { RouteMeta } from '~operation/router/types';
import { PAGE_TITLE } from '~operation/constants/pageTitle';
import { helpBox } from '~operation/static/helpBox';

export const trainingsRoutes: RouteRecordRaw[] = [
  {
    // 研修詳細
    path: '/companies/:companyId/operation_cycles/:operationCycleId/trainings/:trainingId',
    name: 'training_detail',
    component: () => import(/* webpackChunkName: "TrainingDetail" */ '~operation/views/operation/training_detail/TrainingDetail.vue'),
    props: (route) => ({
      companyId: Number(route.params.companyId),
      operationCycleId: Number(route.params.operationCycleId),
      trainingId: Number(route.params.trainingId),
    }),
    meta: {
      title: PAGE_TITLE.TRAINING_DETAIL,
      helpBox: helpBox.training_detail,
    } as RouteMeta,
  },
  {
    // 研修参加者の一括設定
    path: '/companies/:companyId/operation_cycles/:operationCycleId/trainings/:trainingId/bulk_training_settings',
    name: 'bulk_training_settings',
    component: () =>
      import(/* webpackChunkName: "BulkTrainingSettings" */ '~operation/views/operation/bulk_training_settings/BulkTrainingSettings.vue'),
    props: (route) => ({
      companyId: Number(route.params.companyId),
      operationCycleId: Number(route.params.operationCycleId),
      trainingId: Number(route.params.trainingId),
    }),
    meta: { title: PAGE_TITLE.BULK_TRAINING_SETTINGS } as RouteMeta,
  },
];
