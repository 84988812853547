import axios, { type Method, type AxiosResponse } from 'axios';
import { loginWithRedirect, getTokenSilintlyWithValidate, getGuidFromAccessToken } from '~operation/modules/auth';
import { handleError } from '../ErrorHandlers/defaultErrorHandler';
import { VITE_APP_API_BASE_URL } from '~operation/env';
import { IS_MOCK_ENABLED } from '~operation/utility/mockFlg';

const baseDomain = VITE_APP_API_BASE_URL;
const baseURL = `${baseDomain}/v1`;

export default class {
  private static _axiosInstance = this.initializeAxios();

  static async get<T>(url: string, params?: unknown, { shouldShowErrorPage = true } = {}) {
    return this._axiosInstance
      .get<T>(url, { params })
      .then((response: AxiosResponse) => response.data)
      .catch((error) => {
        handleError(error, shouldShowErrorPage);
      });
  }

  static async put<T>(url: string, data?: unknown, { shouldShowErrorPage = false } = {}) {
    return this._axiosInstance
      .put<T>(url, data)
      .then((response: AxiosResponse) => response.data)
      .catch((error) => {
        handleError(error, shouldShowErrorPage);
      });
  }

  static async post<T>(url: string, data?: unknown, { shouldShowErrorPage = false } = {}) {
    return this._axiosInstance
      .post<T>(url, data)
      .then((response: AxiosResponse) => response.data)
      .catch((error) => {
        handleError(error, shouldShowErrorPage);
      });
  }

  static async postMultipartFormData<T>(url: string, data?: unknown, { shouldShowErrorPage = false } = {}) {
    return this._axiosInstance
      .postForm<T>(url, data)
      .then((response: AxiosResponse) => response.data)
      .catch((error) => {
        handleError(error, shouldShowErrorPage);
      });
  }

  static async delete<T>(url: string, data?: unknown, { shouldShowErrorPage = false } = {}) {
    return this._axiosInstance
      .delete<T>(url, { data })
      .then((response: AxiosResponse) => response.data)
      .catch((error) => {
        handleError(error, shouldShowErrorPage);
      });
  }

  static async fileDownload(url: string, method: Method, params?: unknown, { shouldShowErrorPage = false } = {}) {
    return this._axiosInstance({
      method,
      responseType: 'blob',
      url,
      params,
    })
      .then((response: AxiosResponse) => response.data)
      .catch((error) => {
        handleError(error, shouldShowErrorPage);
      });
  }

  static initializeAxios() {
    const axiosInstance = axios.create({
      baseURL,
      headers: {
        'content-type': 'application/json',
      },
    });

    axiosInstance.interceptors.request.use(async (config) => {
      if (IS_MOCK_ENABLED) {
        return config;
      }

      try {
        const token = await getTokenSilintlyWithValidate();
        const guid = getGuidFromAccessToken(token);
        config.headers.Authorization = `Bearer ${token}`;
        if (guid) {
          config.headers['MCS-User-Guid'] = guid;
        }
        return config;
      } catch {
        loginWithRedirect();
        throw new axios.Cancel('Request cancelled: cannot get token silently');
      }
    });

    return axiosInstance;
  }
}
