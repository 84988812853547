import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const LM_OPERATOR = 10000;

export const get: GetResponseResolver = ({ request }) => {
  const url = new URL(request.url);
  const companyId = Number(url.searchParams.get('company_id'));
  if (companyId >= LM_OPERATOR) {
    return HttpResponse.json(
      {
        permissions: ['operation_setting', 'lm_operation_setting', 'survey_target', 'follower'],
      },
      { status: 200 }
    );
  }

  return HttpResponse.json(
    {
      permissions: ['operation_setting', 'survey_target', 'follower'],
    },
    { status: 200 }
  );
};

export const myPermissions = {
  get,
};
