import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

export const get: GetResponseResolver = () => {
  return HttpResponse.json(
    {
      companies: [
        {
          code: '1',
          name: 'シナリオテスト企業1',
        },
        {
          code: '2',
          name: 'シナリオテスト企業2',
        },
        {
          code: '3',
          name: 'シナリオテスト企業3',
        },
      ],
      totalCount: 3,
    },
    { status: 200 }
  );
};

export const organizations = {
  get,
};
