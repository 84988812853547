import {
  chunkFailedRegExp,
  dynamicallyImportedModuleRegExp,
  unableToPreloadCSSRegExp,
  requestFailedWithStatusCode403,
  requestFailedWithStatusCode401,
  networkError,
} from './errorReqExp';

export const ignoreErrors = [
  chunkFailedRegExp,
  dynamicallyImportedModuleRegExp,
  unableToPreloadCSSRegExp,
  requestFailedWithStatusCode403,
  requestFailedWithStatusCode401,
  networkError,
];
