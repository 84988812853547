import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const SETTING_COMPLETE_CASE = '3';
const COMPLETE_CASE = '5';
const SYSTEM_ERROR = '500';

export const get: GetResponseResolver = ({ params }) => {
  if (params.companyId === SYSTEM_ERROR) {
    return HttpResponse.json({}, { status: 500 });
  }

  if (params.trainingId === SETTING_COMPLETE_CASE) {
    return HttpResponse.json(
      {
        training: {
          id: 3,
          operationCycle: '運用設定名1',
          type: 'サーベイフィードバック研修（全日）',
          status: '設定完了',
          startedAt: '2023-11-15T14:00:00+09:00',
          endedAt: '2023-11-15T15:00:00+09:00',
        },
      },
      { status: 200 }
    );
  }

  if (params.trainingId === COMPLETE_CASE) {
    return HttpResponse.json(
      {
        training: {
          id: 5,
          operationCycle: '運用設定名1',
          type: 'サーベイフィードバック研修（半日）',
          status: '終了',
          startedAt: '2023-11-13T09:00:00+09:00',
          endedAt: '2023-11-13T16:00:00+09:00',
        },
      },
      { status: 200 }
    );
  }

  return HttpResponse.json(
    {
      training: {
        id: 2,
        operationCycle: '運用設定名1',
        type: 'サーベイフィードバック研修（全日）',
        status: '設定未完了',
        startedAt: '2023-02-10T10:00:00+09:00',
        endedAt: '2023-02-10T11:00:00+09:00',
      },
    },
    { status: 200 }
  );
};

export const training = {
  get,
};
