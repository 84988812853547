import { HttpResponse } from 'msw';
import type { GetResponseResolver, PutResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const SYSTEM_ERROR = '500';
const INPROGRESS = '3';

export const get: GetResponseResolver = ({ params }) => {
  if (params.companyId === SYSTEM_ERROR) {
    return HttpResponse.json({}, { status: 500 });
  }

  if (params.operationCycleId === INPROGRESS) {
    return HttpResponse.json(
      {
        operationCycle: {
          id: 1,
          name: '運用設定 削除可能',
          hideable: true,
        },
        surveyPlan: {
          surveyTypeId: 1,
          executionPeriod: {
            '1': false,
            '2': true,
            '3': false,
            '4': false,
            '5': false,
            '6': false,
            '7': false,
            '8': false,
            '9': false,
            '10': false,
            '11': false,
            '12': false,
          },
          answerStartWeek: 1,
          answerStartWday: 3,
          answerStartHour: 10,
          answerPeriodDays: 5,
          answerPeriodEndHour: 18,
        },
      },
      { status: 200 }
    );
  }

  return HttpResponse.json(
    {
      operationCycle: {
        id: 1,
        name: '運用設定名1',
        hideable: false,
      },
      surveyPlan: {
        surveyTypeId: 1,
        executionPeriod: {
          '1': false,
          '2': true,
          '3': false,
          '4': false,
          '5': false,
          '6': false,
          '7': false,
          '8': false,
          '9': false,
          '10': false,
          '11': false,
          '12': false,
        },
        answerStartWeek: 1,
        answerStartWday: 3,
        answerStartHour: 10,
        answerPeriodDays: 5,
        answerPeriodEndHour: 18,
      },
    },
    { status: 200 }
  );
};

export const put: PutResponseResolver = () => {
  return HttpResponse.json({}, { status: 200 });
};

export const operationCycle = {
  get,
  put,
};
