import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const SYSTEM_ERROR = '500';

const get: GetResponseResolver = ({ params }) => {
  if (params.companyId === SYSTEM_ERROR) {
    return HttpResponse.json({}, { status: 500 });
  }

  return HttpResponse.json(
    {
      uploadHistories: [
        {
          id: 4,
          startedAt: '2023-02-10T18:39:41+09:00',
          executeUserName: '名前がとても長いLM社員',
          status: 'import_finished',
          fileDownloadable: true,
          errorFileDownloadable: false,
          filename: '閲覧者設定.csv',
          type: 'viewer_setting',
        },
        {
          id: 3,
          startedAt: '2023-02-10T18:39:41+09:00',
          executeUserName: 'LM社員',
          status: 'validate_failed',
          fileDownloadable: true,
          errorFileDownloadable: true,
          filename: '閲覧者設定.csv',
          type: 'viewer_setting',
        },
        {
          id: 2,
          startedAt: '2023-02-10T18:39:41+09:00',
          executeUserName: 'LM社員',
          status: 'validate_failed',
          fileDownloadable: true,
          errorFileDownloadable: true,
          filename: '20230517_対象者回答者設定本当の最終版ver2.csv',
          type: 'questionnaire_setting',
        },
        {
          id: 1,
          startedAt: '2023-02-10T18:37:41+09:00',
          executeUserName: 'LM社員',
          status: 'import_finished',
          fileDownloadable: true,
          errorFileDownloadable: false,
          filename: '対象者回答者設定.csv',
          type: 'questionnaire_setting',
        },
      ],
    },
    { status: 200 }
  );
};

export const surveyQuestionnaireSettingUploadHistories = { get };
