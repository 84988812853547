import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const EMPTY_CASE = '2';
const SYSTEM_ERROR = '500';

export const get: GetResponseResolver = ({ params }) => {
  if (params.companyId === SYSTEM_ERROR) {
    return HttpResponse.json({}, { status: 500 });
  }

  if (params.operationCycleId === EMPTY_CASE) {
    return HttpResponse.json({ surveys: [] }, { status: 200 });
  }

  return HttpResponse.json(
    {
      surveys: [
        {
          id: 2,
          startedAt: '2024-02-07T10:00:00+09:00',
          endedAt: '2024-02-11T18:00:00+09:00',
        },
        {
          id: 1,
          startedAt: '2023-01-24T19:00:00+09:00',
          endedAt: '2023-01-31T19:00:00+09:00',
        },
      ],
    },
    { status: 200 }
  );
};

export const operationCycleSurveysWithQuestionnaires = {
  get,
};
