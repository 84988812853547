import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const get: GetResponseResolver = async () => {
  return HttpResponse.json({
    answerTargetUsers: [
      {
        companyUserId: 1,
        name: '今井恭平',
        email: 'imai@example.com',
        bossAnswererCount: 1,
        staffAnswererCount: 2,
      },
      {
        companyUserId: 2,
        name: '植田健人',
        email: 'edasan@example.com',
        bossAnswererCount: 10,
        staffAnswererCount: 3,
      },
    ],
  });
};

export const surveyQuestionnaireSettingAnswerTargetUsers = {
  get,
};
