import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

export const get: GetResponseResolver = () => {
  return HttpResponse.json(
    {
      currentTime: '2023-11-21T18:35:53+09:00',
    },
    { status: 200 }
  );
};

export const currentTime = {
  get,
};
