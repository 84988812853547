import { HttpResponse } from 'msw';
import type { GetResponseResolver } from '~operation/mocks/types/RestResponseResolver';

const SYSTEM_ERROR = '500';

export const get: GetResponseResolver = ({ params }) => {
  if (params.companyId === SYSTEM_ERROR) {
    return HttpResponse.json({}, { status: 500 });
  }

  return HttpResponse.json(
    {
      plans: [
        {
          name: 'ベーシックD×スキルD',
          userLimits: 10000,
        },
      ],
      totalPrice: 0,
    },
    { status: 200 }
  );
};

export const companyPlans = {
  get,
};
